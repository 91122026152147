import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IncomingCall } from '../_models/store';

@Injectable({
  providedIn: 'root'
})
export class CallerIdService {

  endpoint = environment.apiUrl;
  callerId = this.endpoint + '/backend/caller-id'
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  public callSet = new BehaviorSubject<IncomingCall[]>([]);
  public acceptIgnreCall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public setPhoneNumbertoOrderAndDelivery: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}


  updateCallStatus(payload: any) {
    return this.http.post(this.callerId + `/call-status/`, payload, this.httpOptions);
  }
}
