import { Component, Inject } from '@angular/core';
import { ActivateGiftCardDialogComponent } from '../activate-gift-card-dialog/activate-gift-card-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { StoreService } from 'src/app/_services/store.service';
import { RefreshService } from 'src/app/_services/refresh.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-add-gift-card-value',
  templateUrl: './add-gift-card-value.component.html',
  styleUrls: ['./add-gift-card-value.component.scss']
})

export class AddGiftCardValueComponent {
  formattedValue: string = '0.00';
  isAmountValid: boolean = false;
  private destroySubject: Subject<void> = new Subject();
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AddGiftCardValueComponent>,
    private storeService: StoreService,
    private refreshService: RefreshService,
    private posSummaryToggleService: POSSummaryToggleService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value.replace(/[^0-9]/g, ''); // Allow only numbers
    // Ensure the value has two decimal places
    const decimalValue = parseFloat(inputValue) / 100; // Divide by 100 to shift decimal place
    this.formattedValue = decimalValue.toFixed(2); // Format with 2 decimal places
    this.isAmountValid = decimalValue > 0;
  }

  onNext() {
    if (this.data?.from == 'balance-check-popup') {
      this.addInMiscItem(this.data.cardDetails.card_type, this.data.cardDetails.card_number, this.formattedValue);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(ActivateGiftCardDialogComponent, {
        disableClose: true,
        width: isMobile ? '100vw' : '600px',
        data: { for: 'gift-card-addvalue', amount: this.formattedValue, store: this.data.order.store },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data) {
          this.addInMiscItem(result.cardDetails.card_type, result.cardDetails.card_number, result.cardAmount);
        }
      });
    }
  }

  addInMiscItem(type, number, value) {
    const payload = {
      bot_order_id: this.data?.from == 'balance-check-popup' ? this.data.id : this.data.order.bot_order.id,
      is_gift_card: true,
      card_type: type,
      items: [{ id: null, is_taxable: false, item_name: number, price: value, quantity: 1, is_gift_card: true }]
    };
    this.storeService.addMiscItems(payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.posSummaryToggleService.toggle('OPEN')
      this.refreshService.refreshModule("POS_ORDER_SUMMARY");
      this.dialogRef.close(true);
    });
  }
  
  onBack() {
    this.dialogRef.close(false);
  }
}