import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorLoggingService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  logError(error) {
    console.log(error);
    this.http.post<any>(`${environment.apiUrl}/backend/v2/error/`, {error: `${error.message}\n${error.stack}`}, this.httpOptions).subscribe(response => {
      console.log(response);
    });
    }
  }

