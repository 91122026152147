<app-progress-spinner *ngIf="this.isLoading"></app-progress-spinner>
<div class="search-form width-100 menu-category">
  <div class="search-items pull-left">
    <mat-form-field class="search-form-field" appearance="fill">
      <mat-label>Search Items</mat-label>
      <input matInput type="text" [(ngModel)]="searchTerm" (keydown)="search($event.value)">
      <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''; search('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="availability pull-left">
    <mat-form-field class="search-filter" appearance="fill">
      <mat-label>Availability</mat-label>
      <mat-select [(ngModel)]="availabilityFilter" (selectionChange)="search($event.value)">
        <mat-option value="">All</mat-option>
        <mat-option value="unavailable">Unavailable</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="clear"></div>
</div>


<div *ngIf="searchTerm || availabilityFilter; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
  <ng-container *ngFor="let menuItemGroup of searchResults">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="text-base">{{menuItemGroup.name}}</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-menu-item-search-result [store]="store" [menuItemGroup]="menuItemGroup"></app-menu-item-search-result>
      </ng-template>
    </mat-expansion-panel>

  </ng-container>
</ng-template>

<ng-template #elseBlock>
  <mat-accordion cdkDropList [cdkDropListData]="menuGroupsOrdered" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let menuGroup of menuGroupsOrdered">
      <!-- Entire row is draggable -->
      <mat-expansion-panel cdkDrag class="no-select">
        <mat-expansion-panel-header>
          <mat-panel-title class="flex items-center">
            <!-- Drag Handle on Icon -->
            <span cdkDragHandle class="cursor-move">
              <mat-icon>drag_indicator</mat-icon>
            </span>
            <span class="ml-2">{{ menuGroup.name }}</span>
            <span *ngIf="menuGroup.subgroup">&nbsp;-&nbsp;{{ menuGroup.subgroup }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-menu-item-group #m
            [menuGroup]="menuGroup"
            [store]="store"
            (menuGroupUpdate)="setMenuGroup($event)"
            (menuGroupOrderUpdate)="updateOrder($event)"
            (refresh)="menuGroupReset()"
          ></app-menu-item-group>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <br />
  <app-add-new [store]="store" (refresh)="menuGroupReset()"></app-add-new>
</ng-template>