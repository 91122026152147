import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-gift-card-physical',
  templateUrl: './gift-card-physical.component.html',
  styleUrls: ['./gift-card-physical.component.scss'],
  animations: [
    trigger('detailExpand', [state('collapsed, void', style({ height: '0px' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')), transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])
  ],
})

export class GiftCardPhysicalComponent {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('fileInput', { static: false }) fileInputRef!: ElementRef;// Reference to the file input element
  selectedFile: File | null = null;
  private unsubscribe = new Subject<void>();
  store_id: any;
  search_str: string;
  from_date: string;
  to_date: string;
  pageNumber: number = 0;
  pageSize: number = 10;
  startDate: any;
  columnsToDisplay = ['name', 'phone_number', 'status', 'card_number', 'card_type', 'balance', 'created_at', 'action'];
  dataSource: any;
  expandedRowId;
  filterCount: any;
  selectedRow: any;
  expandedElement: PeriodicElement | null;
  innerHistory = ['id', 'redeemed_amount', 'transaction_id', 'redeemed_at'];
  innerDatasource: any;

  constructor(private storeService: StoreService) { }

  ngOnInit() {
    this.store_id = localStorage.getItem('currentStore');
    this.sort = new MatSort();
    this.giftCardList(0);
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      console.log('Selected file:', this.selectedFile.name);
    } else {
      this.selectedFile = null;
    }
  }

  onSubmit(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('store', this.store_id);
      this.storeService
        .uploadBulkPhysicalGiftCardData(formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {
            this.selectedFile = null;
            if (this.fileInputRef && this.fileInputRef.nativeElement) {
              this.fileInputRef.nativeElement.value = '';
            }
          },
          (error) => {
            console.error('Error occurred during upload:', error);
          }
        );
    }
  }

  giftCardList(e) {
    this.search_str = '';
    this.from_date = '';
    this.to_date = '';
    this.pageNumber = 0;
    this.pageSize = 10;
    if (e == 0) {
      this.sort.active = '';
      this.sort.direction = '';
    }
    this.storeService.getGiftCards(this.store_id, this.pageNumber, this.pageSize, this.search_str, this.sort.active,
      this.sort.direction, this.from_date, this.to_date).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data.data);
        // this.dataSource.sort = this.sort;
        this.filterCount = data.count;
        this.selectedRow = null;
        this.expandedRowId = null;
      });
  }

  giftCardListSecond() {
    if (this.sort.active == 'id') {
      this.sort.active = '';
      this.sort.direction = '';
    }
    this.storeService.getGiftCards(this.store_id, this.pageNumber, this.pageSize, this.search_str,this.sort.active,
      this.sort.direction, this.from_date, this.to_date).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data.data);
      // this.dataSource.sort = this.sort;
      this.filterCount = data.count;
      this.selectedRow = null;
      this.expandedRowId = null;
    });
  }

  redemptionHistory(id) {
    this.storeService.getGiftCardRedemptionHistory(id).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      console.log('data');
      this.innerDatasource = new MatTableDataSource(data.data);
    });
  }

  searchOrders(any: any) {
    this.giftCardListSecond();
  }

  onRowClicked(row) {
    this.selectedRow = row;
    this.redemptionHistory(row.id);
    // this.phoneNumber = row.phone_number.replace(/\+/g, '');
    // this.giftOrderUserList();
    // this.giftCardLogHistory();
  }

  reset() {
    this.paginator.pageSize = 10;
    this.giftCardList(0);
  }

  yourHandler(q) {
    this.pageNumber = q.pageIndex;
    this.pageSize = q.pageSize;
    this.giftCardListSecond();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }
}
export interface PeriodicElement {
  name: any;
  phone_number: any;
  status: any;
  card_number: any;
  card_type: any;
  current_balance: any;
  created_at: any;
  action: any;
}