import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { OrdersWithPayment } from 'src/app/_models/order';
@Component({
  selector: 'app-gift-card-partial-payment',
  templateUrl: './gift-card-partial-payment.component.html',
  styleUrls: ['./gift-card-partial-payment.component.scss']
})
export class GiftCardPartialPaymentComponent {
  crdBalance: number;
  amount: number;
  remainingAmount: number;
  constructor(
    public dialogRef: MatDialogRef<GiftCardPartialPaymentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('Gift Card Dialog Data', data);

    this.crdBalance = this.data.data.card_blnce;
    this.amount = this.data.data.amnt;
    this.remainingAmount = Number((this.amount - this.crdBalance).toFixed(2));
  }
  
  ngOnInit() {
  }

  partialChekout(pt: string) {
    if (pt === 'cash') {
      const orderHash = localStorage.getItem('order_hash');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const isMobile = window.innerWidth <= 470;
      const dialogRef = this.dialog.open(CheckoutDialogComponent, {
        id: 'gift-checkout-dialog',
        width: isMobile ? '100vw' : '630px',
        height: isMobile ? 'auto' : '660px',
        maxWidth: isMobile ? '100vw' : '',
        data: {
          openFromgift: true,
          orderWithPayment: this.data.data.order,
          terminalId: this.data.data.terminalId,
          cashTotal: Number((this.amount - this.crdBalance).toFixed(2)),
          cashDueTotal: Number((this.amount - this.crdBalance).toFixed(2)),
          card_amount: this.crdBalance,
          card_id: this.data.data.card_id,
          from: 'gift-partial-payment'

        },
        panelClass: 'checkout-mobile',
      });

      dialogRef.afterClosed().subscribe(isComplete => {
        if (isComplete) {
          this.dialogRef.close(isComplete);
          this.dialog.closeAll();
        }
      });
    } else {
      this.closePartialCash(pt);
    }

  }

  closePartialCash(e) {
    const data = {
      data: true,
      type: e
    }
    this.dialogRef.close(data);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}