<div class="w-full flex flex-col gap-5 2xl:gap-10 items-center h-[calc(100vh-150px)] p-5">
  <div class="flex flex-row justify-between w-full">
    <button mat-icon-button mat-dialog-close *ngIf="hasBack"><mat-icon>arrow_back</mat-icon></button>
    <p *ngIf="!hasBack"></p>
    <p class="text-2xl pb-5 pt-2">Would you like to print the receipt?</p>
    <p></p>
  </div>

  <button mat-flat-button color="primary" class="text-2xl w-full !h-16 2xl:!h-20 xl:pt-5" (click)="receiptPrinting(1)" [disabled]="!isSuccess">STATION AND KITCHEN</button>
  <button mat-flat-button color="primary" class="text-2xl w-full !h-16 2xl:!h-20 xl:pt-5" (click)="receiptPrinting(0)" [disabled]="!isSuccess">DO NOT PRINT RECEIPT</button>
  <button mat-flat-button color="primary" class="text-2xl w-full !h-16 2xl:!h-20 xl:pt-5" (click)="receiptPrinting(2)" [disabled]="!isSuccess">KITCHEN ONLY</button>
  <button mat-flat-button color="primary" class="text-2xl w-full !h-16 2xl:!h-20 xl:pt-5" (click)="receiptPrinting(3)" [disabled]="!isSuccess">STATION ONLY</button>
</div>


