import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Konva from 'konva';
import { AddNewFloorPlanComponent } from '../add-new-floor-plan/add-new-floor-plan.component';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';
import { logWarnings } from 'protractor/built/driverProviders';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-floor-plan-view',
  templateUrl: './floor-plan-view.component.html',
  styleUrls: ['./floor-plan-view.component.scss']
})
export class FloorPlanViewComponent {

  @ViewChild('stageContainer', { static: true }) stageContainer!: ElementRef;

  stage: any;
  layer: any;
  tablesList: any;
  objectList: any;
  transformer: Konva.Transformer;
  private destroySubject: Subject<void> = new Subject();
  alignmentDirection: 'horizontal' | 'vertical' = 'vertical';
  gridSize: number = 10; // Size of the grid cells

  constructor(public floorPlanService: FloorPlanService, public dialogRef: MatDialogRef<FloorPlanViewComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.initializeKonva();
  }

  initializeKonva(): void {
    const floorWidth = this.data.floor.width;
    const floorHeight = this.data.floor.height;
  
    this.stage = new Konva.Stage({
      container: this.stageContainer.nativeElement,
      width: floorWidth,
      height: floorHeight,
    });
  
    this.layer = new Konva.Layer();
    this.stage.add(this.layer);
  
    this.transformer = new Konva.Transformer();
    this.layer.add(this.transformer);
  
    // Draw the grid
    this.drawGrid();
  
    this.floorPlanService.getFloorObjectList(this.data.floor.id).pipe(takeUntil(this.destroySubject)).subscribe((objectList) => {
      this.objectList = objectList;
      this.renderObjects(objectList);
      this.floorPlanService.getFloorTableList(this.data.floor.id).pipe(takeUntil(this.destroySubject)).subscribe((tableList) => {
        this.tablesList = tableList;
        this.renderTables(tableList);
      });
    });
  }
  
  drawGrid(): void {
    const canvasWidth = this.stage.width();
    const canvasHeight = this.stage.height();
  
    for (let x = 0; x <= canvasWidth; x += this.gridSize) {
      const line = new Konva.Line({
        points: [x, 0, x, canvasHeight],
        stroke: '#ddd',
        strokeWidth: 1,
        listening: false,
      });
      this.layer.add(line);
    }
  
    for (let y = 0; y <= canvasHeight; y += this.gridSize) {
      const line = new Konva.Line({
        points: [0, y, canvasWidth, y],
        stroke: '#ddd',
        strokeWidth: 1,
        listening: false,
      });
      this.layer.add(line);
    }
  
    this.layer.draw();
  }
  
  snapToGrid(value: number): number {
    return Math.round(value / this.gridSize) * this.gridSize;
  }
  
  renderObjects(objects: any): void {
    const canvasWidth = this.stage.width();
    const canvasHeight = this.stage.height();
  
    objects.forEach((obj) => {
      let shape;
      const {
        width, height, object_name, rotation,
        x_position, y_position, color
      } = obj;
  
      const fillColor = color || '#ccc';
  
      let xPos = this.snapToGrid(x_position || 0);
      let yPos = this.snapToGrid(y_position || 0);
  
      xPos = Math.max(0, Math.min(xPos, canvasWidth - width));
      yPos = Math.max(0, Math.min(yPos, canvasHeight - height));
  
      shape = new Konva.Rect({
        x: xPos,
        y: yPos,
        width: width,
        height: height,
        fill: fillColor,
        stroke: 'black',
        strokeWidth: 2,
        draggable: true,
        rotation: rotation,
      });
  
      const text = new Konva.Text({
        text: `${object_name}`,
        fontSize: 14,
        fontFamily: 'Calibri',
        fill: 'black',
        width: width,
        align: 'center',
      });
  
      const updateTextPosition = () => {
        const shapePos = shape.position();
        const textWidth = text.width();
        const textHeight = text.height();
  
        text.position({
          x: shapePos.x + (shape.width() - textWidth) / 2,
          y: shapePos.y + (shape.height() - textHeight) / 2,
        });
  
        text.moveToTop();
      };
  
      updateTextPosition();
  
      shape.on('dragend', () => {
        const newPos = shape.position();
        obj.x_position = this.snapToGrid(newPos.x);
        obj.y_position = this.snapToGrid(newPos.y);
        shape.position({ x: obj.x_position, y: obj.y_position });
        updateTextPosition();
        shape.getLayer().batchDraw();
  
        console.log('Updated position of object:', object_name, 'x:', obj.x_position, 'y:', obj.y_position);
      });
  
      shape.on('transformend', () => {
        // Save the new scaled dimensions without resetting the scale
        obj.width = shape.width() * shape.scaleX();
        obj.height = shape.height() * shape.scaleY();
        obj.rotation = shape.rotation(); 
        console.log('Updated dimensions of object:', object_name, 'width:', obj.width, 'height:', obj.height);
      });
  
      text.on('mousedown touchstart', () => {
        shape.startDrag();
      });
  
      shape.on('click touchstart', () => {
        this.transformer.nodes([shape]);
        this.transformer.show();
        this.layer.draw();
      });
  
      this.stage.on('click touchend', (e) => {
        if (e.target === this.stage) {
          this.transformer.hide();
          this.layer.draw();
        }
      });
  
      this.layer.add(shape);
      this.layer.add(text);
    });
  
    this.layer.draw();
  }
  
  renderTables(tables: any): void {
    const canvasWidth = this.stage.width();
    const canvasHeight = this.stage.height();
  
    tables.forEach((table) => {
      let shape;
      const {
        width, height, shape: tableShape, table_name, seating_capacity, rotation,
        x_position, y_position, color
      } = table;
  
      const fillColor = color || '#ccc';
  
      let xPos = this.snapToGrid(x_position || 0);
      let yPos = this.snapToGrid(y_position || 0);
  
      xPos = Math.max(0, Math.min(xPos, canvasWidth - width));
      yPos = Math.max(0, Math.min(yPos, canvasHeight - height));
  
      if (tableShape === 'square' || tableShape === 'rectangle') {
        shape = new Konva.Rect({
          x: xPos,
          y: yPos,
          width: width,
          height: height,
          fill: fillColor,
          stroke: 'black',
          strokeWidth: 2,
          draggable: true,
          rotation: rotation,
        });
      } else if (tableShape === 'round') {
        shape = new Konva.Circle({
          x: xPos + width / 2,
          y: yPos + height / 2,
          radius: width / 2,
          fill: fillColor,
          stroke: 'black',
          strokeWidth: 2,
          draggable: true,
          rotation: rotation,
        });
      }
  
      const text = new Konva.Text({
        text: `${table_name} (${seating_capacity})`,
        fontSize: 14,
        fontFamily: 'Calibri',
        fill: 'black',
        width: width,
        align: 'center',
      });
  
      const updateTextPosition = () => {
        const shapePos = shape.position();
        const textWidth = text.width();
        const textHeight = text.height();
  
        if (tableShape === 'round') {
          text.position({
            x: shapePos.x - textWidth / 2,
            y: shapePos.y - textHeight / 2,
          });
        } else {
          text.position({
            x: shapePos.x + (shape.width() - textWidth) / 2,
            y: shapePos.y + (shape.height() - textHeight) / 2,
          });
        }
  
        text.moveToTop();
      };
  
      updateTextPosition();
  
      shape.on('dragmove', () => {
        updateTextPosition();
      });
  
      shape.on('dragend', () => {
        const newPos = shape.position();
        if (tableShape === 'round') {
          table.x_position = this.snapToGrid(newPos.x - shape.radius());
          table.y_position = this.snapToGrid(newPos.y - shape.radius());
        } else {
          table.x_position = this.snapToGrid(newPos.x);
          table.y_position = this.snapToGrid(newPos.y);
        }
  
        shape.position({
          x: tableShape === 'round' ? table.x_position + shape.radius() : table.x_position,
          y: tableShape === 'round' ? table.y_position + shape.radius() : table.y_position
        });
  
        updateTextPosition();
        shape.getLayer().batchDraw();
  
        console.log('Updated position of table:', table_name, 'x:', table.x_position, 'y:', table.y_position);
      });
  
      shape.on('transformend', () => {
        // Save the new scaled dimensions without resetting the scale
        table.width = shape.width() * shape.scaleX();
        table.height = shape.height() * shape.scaleY();
        table.rotation = shape.rotation();
        console.log('Updated dimensions of table:', table_name, 'width:', table.width, 'height:', table.height);
      });
  
      text.on('mousedown touchstart', () => {
        shape.startDrag();
      });
  
      shape.on('click touchstart', () => {
        this.transformer.nodes([shape]);
        this.transformer.show();
        this.layer.draw();
      });
  
      shape.on('touchmove', (e) => {
        e.evt.preventDefault(); // Prevent default to avoid scrolling
      });

      this.stage.on('click touchend', (e) => {
        if (e.target === this.stage) {
          this.transformer.hide();
          this.layer.draw();
        }
      });
  
      this.layer.add(shape);
      this.layer.add(text);
    });
  
    this.layer.draw();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
    this.stage.destroy();
  }

  addTable() {
    let dialogRef = this.dialog.open(AddNewFloorPlanComponent, {
      disableClose: true,
      width: '570px',
      height: '400px',
      data: {
        from: 'add-table',
        f_id: this.data.floor.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.initializeKonva();
      }
    });
  }

  addObject() {
    let dialogRef = this.dialog.open(AddNewFloorPlanComponent, {
      disableClose: true,
      width: '570px',
      height: '240px',
      data: {
        from: 'add-object',
        f_id: this.data.floor.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.initializeKonva();
      }
    });
  }

  onSubmit(): void {
    this.floorPlanService.updateFloorObjects(this.objectList).pipe(takeUntil(this.destroySubject)).subscribe(data => {
      this.floorPlanService.updateFloorTables(this.tablesList).pipe(takeUntil(this.destroySubject)).subscribe(data => {
        this.dialogRef.close(true);
      });
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
