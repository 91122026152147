import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MenuServiceV2} from 'src/app/_services/menu-v2.service';
import {Store, MenuResetOptions} from 'src/app/_models/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrinterSettingsService } from 'src/app/_services/printer-settings.service';

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AddNewComponent implements OnInit {

  @Input() store: Store;
  @Output() refresh = new EventEmitter<void>();

  newMenuItemGroupForm: UntypedFormGroup;
  columnsToDisplay = ['addMenu'];
  expandedElement: any;
  newMenuDatasource = [{"addMenu": "Add menu category"}];
  resetOptions = MenuResetOptions;

  @ViewChild('f', {static: false}) ngForm: any;

  printerSettings: any[] = [];
  storeId: string | null = null;

  constructor(
    private menuService: MenuServiceV2,
    private snackBar: MatSnackBar,
    private printerService: PrinterSettingsService
  ) {}

  ngOnInit() {
    this.newMenuItemGroupForm = this.initializeForm();
    this.storeId = localStorage.getItem('currentStore');
    this.loadPrinterSettings();
  }

  initializeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      menu_group_name: new UntypedFormControl('', Validators.required),
      subgroup: new UntypedFormControl(''),
      assignto_printer: new UntypedFormControl([]),
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      is_taxable: new UntypedFormControl(true, Validators.required),
      menu_items: new UntypedFormArray([
        this.createMenuItem()
      ])
    });
  }

  createMenuItem(): UntypedFormGroup {
    return new UntypedFormGroup({
      portion: new UntypedFormControl('', Validators.required),
      base_price: new UntypedFormControl('', Validators.required),
      barcode: new UntypedFormControl(null),
      reset: new UntypedFormControl(null),
      food_cost: new UntypedFormControl(''),
      is_available: new UntypedFormControl(true, Validators.required),
      is_taxable: new UntypedFormControl(true, Validators.required),
      is_pos_only: new UntypedFormControl(false, Validators.required),
      available_qty: new UntypedFormControl(null)
    });
  }

  addNewMenuItemGroup(values: any): void {
    if (this.newMenuItemGroupForm.valid) {
      this.menuService.addMenuItemGroup(this.store.id, JSON.stringify(values)).subscribe(() => {
        this.snackBar.open('Item has been added', 'OK', { duration: 2000 });
        this.expandedElement = null;
        this.ngForm.resetForm();
        this.refresh.emit();
        this.newMenuItemGroupForm = this.initializeForm();
      });
    } else {
      this.snackBar.open('Please fill all required fields.', 'OK', { duration: 2000 });
    }
  }

  addPortionNewMenuItemGroup(): void {
    const menuItems = this.newMenuItemGroupForm.get('menu_items') as UntypedFormArray;
    menuItems.push(this.createMenuItem());
  }

  deleteMenuItemFromNewForm(index: number): void {
    const menuItems = this.newMenuItemGroupForm.get('menu_items') as UntypedFormArray;
    if (menuItems.length > 1) {
      menuItems.removeAt(index);
    } else {
      this.snackBar.open('At least one menu item is required.', 'OK', { duration: 2000 });
    }
  }

  loadPrinterSettings(): void {
    if (this.storeId) {
      this.printerService.getPrinterSettings(this.storeId).subscribe(data => {
        this.printerSettings = [{name: 'All', id: 'set_to_all'}, ...data];
      });
    }
  }

  getPortionOptions(): string[] {
    return ['Standard', 'Small', 'Medium', 'Large'];
  }
}
