<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div *ngIf="merchantErrors && !merchantErrors.is_valid" class="errors">
  <ul>
    <li *ngIf="merchantErrors.create_merchant_response">Gateway Response: {{merchantErrors.create_merchant_response}}
    </li>
    <li *ngIf="merchantErrors.signature_url_response">Gateway Response: {{merchantErrors.signature_url_response}}</li>
  </ul>
</div>
<div class="p-6" *ngIf="!isLoading">

  <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
    <h3>Account Information <mat-icon class="edit-icon material-icons-outlined" (click)="editStoreMerchant('Account Information', 'copilot_merchant',
      ['dba_name', 'business_address_address', 'business_address_city',
        'business_address_state', 'business_address_zip'])">edit</mat-icon>
    </h3>

    <table cellspacing="10" cellpadding="10" width="100%">
      <tr class="mat-row">
        <td>Business Name</td>
        <td>{{storeMerchant?.copilot_merchant.dba_name}}</td>
      </tr>
      <tr>
        <td>Physical Address</td>
        <td>{{storeMerchant?.copilot_merchant.business_address_address}}</td>
      </tr>
      <tr>
        <td></td>
        <td>{{storeMerchant?.copilot_merchant.business_address_city}}
          {{storeMerchant?.copilot_merchant.business_address_state}}
          {{storeMerchant?.copilot_merchant.business_address_zip}}</td>
      </tr>
    </table>
  </div>


  <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
    <h3>Primary Account Contact <mat-icon class="edit-icon material-icons-outlined"
        (click)="editStoreMerchant('Primary Account Contact', 'store' ,['contact_name', 'contact_email', 'contact_phone'])">
        edit</mat-icon>
    </h3>
    <table cellspacing="10" cellpadding="10" width="100%">
      <tr>
        <td>Name</td>
        <td>{{storeMerchant?.store.contact_name}}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{storeMerchant?.store.contact_email}}</td>
      </tr>
      <tr>
        <td>Phone</td>
        <td>{{storeMerchant?.store.contact_phone | phone}}</td>
      </tr>
    </table>
  </div>

  <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
    <h3>Account Settings</h3>

    <table cellspacing="10" cellpadding="10" width="100%">
      <tr>
        <td>Logo</td>
        <td>
          <div class="logo" *ngIf="logo">
            <img [src]="logo">
          </div>
          <div class="action">
            <button mat-flat-button mat-stroked-button (click)="deleteLogo()">Delete</button>
            <button mat-flat-button color="primary" class="apply-btn-colr"
              (click)="openUploadDialog1('logo', false, null)">{{
              logo ? 'Change': 'Upload' }}</button>
          </div>

        </td>

        <!-- <app-store-logo [storeMerchant]="storeMerchant"></app-store-logo> -->
        <!-- <div cellspacing="10" cellpadding="10" width="100%">
          <div>
            <div>
              Logo
            </div>
            <div class="logo">
              <img [src]="logo">
            </div>
            <div class="action">
              <button mat-flat-button mat-stroked-button (click)="deleteLogo()">Delete</button>
            </div>
            <button mat-flat-button color="primary" class="apply-btn-colr"
              (click)="openUploadDialog1('logo', false, null)">{{
              logo ? 'Change': 'Upload' }}</button>
          </div>
          <div>
            <div>
              Favicon
            </div>
            <div class="logo">
              <img [src]="favicon_icon">
            </div>
            <div class="action">
              <button mat-flat-button mat-stroked-button (click)="deleteFav()">Delete</button>
              <button mat-flat-button color="primary" class="apply-btn-colr"
                (click)="openUploadDialogFav('logo', false, null)">{{ logo ? 'Change': 'Upload' }}</button>
            </div>
          </div>
        </div> -->

      </tr>
      <tr>
        <td>Favicon</td>
        <td>
          <div class="logo" *ngIf="favicon_icon">
            <img [src]="favicon_icon">
          </div>
          <div class="action">
            <button mat-flat-button mat-stroked-button (click)="deleteFav()">Delete</button>
            <button mat-flat-button color="primary" class="apply-btn-colr"
              (click)="openUploadDialogFav('logo', false, null)">{{ logo ? 'Change': 'Upload' }}</button>
          </div>
        </td>
      </tr>
    </table>
    <!-- <app-store-favicon [storeMerchant]="storeMerchant"></app-store-favicon> -->


  </div>

  <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
    <h3>Menu</h3>
    <mat-tab-group>
      <mat-tab label="Submit Menu Files">
        <table cellspacing="10" cellpadding="10" width="100%">
          <tr *ngIf="rawStoreMenu.old">
            <td>Last Uploaded</td>
            <td>{{rawStoreMenu?.old.created_at | date:'medium'}}</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <button mat-flat-button color="primary" class="apply-btn-colr"
                (click)="openUploadDialog('menu', true, rawStoreMenu.new.version)">{{rawStoreMenu.old ? 'Update':
                'Upload'}}</button>
            </td>
          </tr>
        </table>
      </mat-tab>
      <mat-tab label="Manage Visual Menu">
        <app-visual-menu [store]="store"></app-visual-menu>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
    <h3>Payment Information <mat-icon class="edit-icon material-icons-outlined"
        (click)="editStoreMerchant('Payment Information','copilot_merchant', ['deposit_bank_name', 'deposit_bank_account_type', 'deposit_bank_routing_number', 'deposit_bank_account_number'])">
        edit</mat-icon>
    </h3>
    <table cellspacing="10" cellpadding="10" width="100%">
      <tr>
        <td>Bank Name</td>
        <td>{{storeMerchant?.copilot_merchant.deposit_bank_name}}</td>
      </tr>
      <tr>
        <td>Account Type</td>
        <td>{{storeMerchant?.copilot_merchant.deposit_bank_account_type}}</td>
      </tr>
      <tr>
        <td>Routing Number</td>
        <td>{{storeMerchant?.copilot_merchant.deposit_bank_routing_number}}</td>
      </tr>
      <tr>
        <td>Account Number</td>
        <td>{{storeMerchant?.copilot_merchant.deposit_bank_account_number}}</td>
      </tr>
      <tr>
        <td>Voided Check</td>
        <td>
          <span *ngIf="storeMerchant?.store.voided_check">
            <a [href]="storeMerchant?.store.voided_check.document"
              target="_blank">{{storeMerchant?.store.voided_check.file_name}}</a> &nbsp;
          </span>

          <button mat-flat-button color="primary" class="apply-btn-colr"
            (click)="openUploadDialog('voidedCheck', false, null)">{{storeMerchant?.store.voided_check ? 'Update':
            'Upload'}}</button>
        </td>
      </tr>
    </table>
  </div> -->

  <!-- <div class="section">
    <h3>Tax Information <mat-icon class="edit-icon material-icons-outlined"
        (click)="editStoreMerchant('Tax Information', 'copilot_merchant',['owner_type', 'owner_title', 'legal_business_name',
    'owner_name', 'owner_address', 'owner_city', 'owner_address_state', 'owner_zip', 'owner_mobile_phone', 'owner_email'])">edit</mat-icon>
    </h3>
    <table cellspacing="10" cellpadding="10" width="100%">
      <tr>
        <td>Entity Type</td>
        <td>{{storeMerchant?.copilot_merchant.owner_type}} - {{storeMerchant?.copilot_merchant.owner_title}}</td>
      </tr>
      <tr>
        <td>Entity Legal Name</td>
        <td>{{storeMerchant?.copilot_merchant.legal_business_name}}
        </td>
      </tr>
      <tr>
        <td>Owner Name</td>
        <td>{{storeMerchant?.copilot_merchant.owner_name}}</td>
      </tr>
      <tr>
        <td>Address</td>
        <td>{{storeMerchant?.copilot_merchant.owner_address}}</td>
      </tr>
      <tr>
        <td></td>
        <td>{{storeMerchant?.copilot_merchant.owner_city}},{{storeMerchant?.copilot_merchant.owner_address_state}},
          {{storeMerchant?.copilot_merchant.owner_zip}} </td>
      </tr>
      <tr>
        <td>Mobile Phone</td>
        <td>{{storeMerchant?.copilot_merchant.owner_mobile_phone | phone}}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{storeMerchant?.copilot_merchant.owner_email}}</td>
      </tr>
    </table>
  </div> -->
  <div *ngIf="storeMerchant?.store.payment_gateway =='STRIPE'">
    <div class="section p-5 mb-5 xs!:p-4 xs:mb-5 ">
      <h3>Stripe Payment Gateway Status</h3>
      <table cellspacing="10" cellpadding="10" width="100%">
        <tr>
          <td>Gateway</td>
          <td>
            <button *ngIf="!stripeDetails['stripe_user_id']" mat-flat-button class="apply-btn-colr"
              (click)="connectWithStripe()" color="primary">Connect</button>
            <button *ngIf="stripeDetails['stripe_user_id']" mat-flat-button class="apply-btn-colr"
              (click)="loginToStripe()" color="primary">Stripe Dashboard</button>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!-- <div *ngIf="storeMerchant?.store.payment_gateway =='CARDCONNECT'">
    <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
      <h3>CardConnect Payment Gateway Status</h3>
      <table cellspacing="10" cellpadding="10" width="100%">
        <tr>
          <td>Gateway</td>
          <td>
            <button
              *ngIf="storeMerchant.copilot_merchant.gateway_boarding_status != 'BOARDED' && !storeMerchant.copilot_merchant.signature_url"
              mat-flat-button color="primary" (click)="activateMerchant('Verify Payment Gateway Details', 'copilot_merchant',
            ['dba_name', 'legal_business_name', 'website_address', 'business_phone',
            'business_address_address', 'business_address_city', 'business_address_state', 'business_address_zip',
            'mailing_address_address', 'mailing_address_city', 'mailing_address_state', 'mailing_address_zip',
            'deposit_bank_name', 'deposit_bank_account_number', 'deposit_bank_account_type', 'deposit_bank_routing_number',
            'withdrawal_bank_name', 'withdrawal_bank_account_number', 'withdrawal_bank_account_type', 'withdrawal_bank_routing_number',
            'owner_name', 'owner_email', 'owner_phone', 'owner_mobile_phone', 'owner_address', 'owner_city',
            'owner_address_state', 'owner_zip'])">Activate</button>

            <a *ngIf="storeMerchant.copilot_merchant.gateway_boarding_status != 'BOARDED' && storeMerchant.copilot_merchant.signature_url"
              [href]="storeMerchant.copilot_merchant.signature_url" target="_blank">
              <button mat-flat-button color="primary">Complete Activation</button>
            </a>

            <button *ngIf="storeMerchant.copilot_merchant.gateway_boarding_status === 'BOARDED'" mat-flat-button
              color="primary" disabled>Live</button>
          </td>
        </tr>
        <tr>
          <td>Boarding Process Status</td>
          <td>{{storeMerchant.copilot_merchant.boarding_process_status}}</td>
        </tr>
        <tr>
          <td>Gateway Boarding Status</td>
          <td>{{storeMerchant.copilot_merchant.gateway_boarding_status}}</td>
        </tr>
      </table>
    </div>
  </div> -->


  <div class="section p-5 mb-5 xs!:p-4 xs:mb-5">
    <h3>User Information</h3>
    <table cellspacing="10" cellpadding="10" width="100%">
      <tr>
        <td>Email</td>
        <td>{{user.username}}</td>
      </tr>
      <tr>
        <td>Password</td>
        <td><button mat-flat-button color="primary" class="apply-btn-colr" (click)="changePassword()">Change
            Password</button></td>
      </tr>
    </table>
  </div>
</div>