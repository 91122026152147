import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {StoreService} from 'src/app/_services/store.service';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-gift-card-holders',
  templateUrl: './gift-card-holders.component.html',
  styleUrls: ['./gift-card-holders.component.scss'],
  animations: [
    trigger('detailExpand', [state('collapsed, void', style({ height: '0px' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')), transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])
  ],
})
export class GiftCardHoldersComponent implements OnInit {

  val: number = 0;
  storeId: any;
  dataSource: any;
  columnsToDisplay = ['name', 'phone_number', 'bot_order_count', 'active_gift_card','total_card_value','current_value', 'last_visit', 'member_since'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'action'];
  expandedElement: PeriodicElement | null;
  innerOrderHistoryDisplayedColumns = ['order_id', 'order_date', 'order_time',
    'payment_type', 'total_spend', 'occasion', 'pos_online'];
  innerDatasource: any;
  innerGiftLogDisplayedColumns = ['operation_date', 'operation_type', 'operation_value'];
  innerGiftLogDatasource: any;
  selectedRow;
  phoneNumber: any;
  pageNumber: any;
  pageSize: any;
  from_date: any;
  to_date: any;
  search_str: any;
  expandedRowId;
  filterCount: any;
  curretnBalance:any;
  cardValueUsed:any;
  startDate: any;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;
  private debounceSubject = new Subject<void>();
  constructor(
    public storeService: StoreService,
    private datePipe: DatePipe,
  ) {
    this.debounceSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.fetchGiftCardHolderUserList();
    });
   }

  ngOnInit() {
    this.sort = new MatSort();
    this.storeId = localStorage.getItem('currentStore');
    this.tableUserListHolder(0);
    this.dataSource.sort = this.sort;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46) { // allow decimal point
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  tableUserListHolder(e) {
    this.from_date = '';
    this.to_date = '';
    this.pageNumber = 0;
    this.pageSize = 10;
    this.search_str = '';
    if (e == 0) {
      this.sort.active = '';
      this.sort.direction = '';
    }
    this.storeService.getGiftCardHolderUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str,this.sort.active,
      this.sort.direction, this.from_date, this.to_date).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data.data);
      // this.dataSource.sort = this.sort;
      this.filterCount = data.count;
      this.selectedRow = null;
      this.expandedRowId = null;
    });
  }

  // tableUserListHolderSecond() {
  //   if (this.sort.active == 'id') {
  //     this.sort.active = '';
  //     this.sort.direction = '';
  //   }
  //   this.storeService.getGiftCardHolderUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str,this.sort.active,
  //     this.sort.direction, this.from_date, this.to_date).subscribe((data: any) => {
  //     this.dataSource = new MatTableDataSource(data.data);
  //     // this.dataSource.sort = this.sort;
  //     this.filterCount = data.count;
  //     this.selectedRow = null;
  //     this.expandedRowId = null;
  //   });
  // }
  tableUserListHolderSecond() {
    if (this.sort.active === 'id') {
      this.sort.active = '';
      this.sort.direction = '';
    }
    this.debounceSubject.next();
  }

  private fetchGiftCardHolderUserList() {
    this.storeService.getGiftCardHolderUserList(
      this.storeId,
      this.pageNumber,
      this.pageSize,
      this.search_str,
      this.sort.active,
      this.sort.direction,
      this.from_date,
      this.to_date
    ).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data.data);
      // this.dataSource.sort = this.sort;
      this.filterCount = data.count;
      this.selectedRow = null;
      this.expandedRowId = null;
    });
  }

  searchOrders(any:any){
    this.tableUserListHolderSecond();
  }

  onRowClicked(row) {
    this.selectedRow = row;
    this.phoneNumber = row.phone_number.replace(/\+/g, '');
    this.giftOrderUserList();
    this.giftCardLogHistory();
  }

  giftOrderUserList() {
    this.storeService.getUserGiftOrderList(this.storeId, this.phoneNumber).subscribe((data: any) => {
      this.innerDatasource = data.last_3_order;
      
    });
  }

  giftCardLogHistory() {
    this.storeService.getGiftCardLogHistory(this.storeId, this.phoneNumber).subscribe((data:any) => {
      this.innerGiftLogDatasource = data.data;
      this.cardValueUsed = data.total_used_balance;
      this.curretnBalance = parseFloat(data.total_current_balance);
    });
  }

  addSubGiftCardValue(input_value, e) {
    const keyData = {
      "phone_number": "+" + this.phoneNumber,
      "store_id": this.storeId,
      "value": input_value,
      "operation":e
    }
    this.storeService.updateGiftCardValue(keyData).subscribe((data:any) => {
      console.log('Updated Value');
      this.val = 0;
      this.giftOrderUserList();
      this.tableUserListHolderSecond();
    });
  }

  longDate(w) {
    if (w.last_visit != null) {
      var ll = this.datePipe.transform(w.last_visit, 'M/dd/yyyy');
      var lastVisit = new Date(ll);
      var dateObj = new Date();
      var cc = this.datePipe.transform(dateObj, 'M/dd/yyyy');
      var currentDate = new Date(cc)
      var Time = currentDate.getTime() - lastVisit.getTime();
      var Days = Time / (1000 * 3600 * 24);
      return Days
    }
    else {
      return '-1'
    }
  }

  reset(){
    this.paginator.pageSize = 10;
    this.tableUserListHolder(0);
  }

  yourHandler(q) {
    this.pageNumber = q.pageIndex;
    this.pageSize = q.pageSize;
    this.tableUserListHolderSecond();
  }

}

export interface PeriodicElement {
  name: any;
  phone_number: any;
  status: any;
  active_gift_card: any;
  total_card_value: any;
  current_balance: any;
  last_visit: any;
  since: any;
}

