<div class="nav" *ngIf="store">
  <div class="app-nav-links">
    <mat-nav-list>
      <a class="ml-1" mat-list-item [routerLink]="['/home/info']" routerLinkActive="is-active"
      *ngIf="permission?.hasPermission('ACCESS', 'HOME')" (click)="onOrdersClick('/home/info')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">store_mall_directory</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Home</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/orders']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('DIGITAL_ORDERING') && permission?.hasPermission('ACCESS', 'ORDERS')" (click)="onOrdersClick('/home/orders')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">shopping_bag</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Orders</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/insights']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('INSIGHTS_MODULE') && permission?.hasPermission('ACCESS', 'INSIGHTS')" (click)="onOrdersClick('/home/insights')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">assessment</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Insights</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/menu']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('MENU_MODULE') && permission?.hasPermission('ACCESS', 'MENU')" (click)="onOrdersClick('/home/menu')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">local_dining</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Menu</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/messages']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('BUSINESS_MESSAGING') && permission?.hasPermission('ACCESS', 'MESSAGES')" (click)="onOrdersClick('/home/messages')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">forum</mat-icon>
        <span class="ml-1 item-name text-sm" [ngClass]='unreadCounter?.total > 0 ? "bold" : ""' *ngIf="opened">Messages</span>
        <span class="unread" *ngIf='unreadCounter?.total > 0 && opened'>{{unreadCounter?.total}}</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/offers']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('DIGITAL_GIFT_CARDS') && permission?.hasPermission('ACCESS', 'GIFT_CARDS')" (click)="onOrdersClick('/home/offers')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">card_giftcard</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Gift Cards</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/offers-v2']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('OFFERS') && permission?.hasPermission('ACCESS', 'OFFERS')" (click)="onOrdersClick('/home/offers-v2')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">local_offer</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Offers</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/campaign']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('SMS_MARKETING') && permission?.hasPermission('ACCESS', 'CAMPAIGNS')" (click)="onOrdersClick('/home/campaign')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">announcement</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Campaign</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/attendant']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('ATTENDANT') && permission?.hasPermission('ACCESS', 'ATTENDANT')" (click)="onOrdersClick('/home/attendant')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">phone_in_talk</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Attendant</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/digital-display']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('DIGITAL_DISPLAY_MODULE') && permission?.hasPermission('ACCESS', 'DIGITAL_DISPLAY')"  (click)="onOrdersClick('/home/digital-display')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">airplay</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Digital Display</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/loyalty']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('LOYALTY_MODULE') && permission?.hasPermission('ACCESS', 'LOYALTY')" (click)="onOrdersClick('/home/loyalty')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">sentiment_satisfied_alt</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Loyalty</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/referral']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('REFERRAL_MODULE') && permission?.hasPermission('ACCESS', 'REFERRAL')" (click)="onOrdersClick('/home/referral')">
        <span class="set-referral">
          <!-- <span><img class="img-width-set" src="../../../assets/referral_icon.png"></span> -->
          <span class="material-symbols-outlined app-nav-list-icon" style="color: #636363;">
            p2p
            </span>
          <span class="google-font ml-1 item-name name-referral-set ml-1 item-name text-sm">Referral</span>
        </span> 
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/skill']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('SKILLS_MODULE') && permission?.hasPermission('ACCESS', 'SKILLS')" (click)="onOrdersClick('/home/skill')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">adb</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Skills</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/feedback']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('FEEDBACK_MODULE') && permission?.hasPermission('ACCESS', 'FEEDBACK')" (click)="onOrdersClick('/home/feedback')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">feedback</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Feedback</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/floor-plan']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('FLOOR_PLAN_MODULE') && permission?.hasPermission('ACCESS', 'FLOOR_PLAN')"
      (click)="onOrdersClick('/home/floor-plan')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">table_restaurant</mat-icon>
        <span class="ml-1 item-name text-sm" *ngIf="opened">Floor Plan</span>
      </a>
    </mat-nav-list>
  </div>
  <mat-divider></mat-divider>
  <div class="app-support-links">
    <mat-nav-list>
      <a class="ml-1" mat-list-item [routerLink]="['/home/account']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('ACCOUNT_MODULE') && permission?.hasPermission('ACCESS', 'ACCOUNT')" (click)="onOrdersClick('/home/account')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">settings</mat-icon>
        <span class="google-font ml-1 item-name text-sm" *ngIf="opened">Account</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/users']" routerLinkActive="is-active"
      *ngIf="store?.modules?.includes('MANAGE_USERS_MODULE') && permission?.hasPermission('ACCESS', 'MANAGE_USERS')" (click)="onOrdersClick('/home/users')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">person_add_alt_1</mat-icon>
        <span class="google-font ml-1 item-name text-sm" *ngIf="opened">Manage Users</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/time-clock']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('TIME_CLOCK_MODULE') && permission?.hasPermission('ACCESS', 'TIME_CLOCK')"  (click)="onOrdersClick('/home/time-clock')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">alarm_on</mat-icon>
        <span class="google-font ml-1 item-name text-sm" *ngIf="opened">Time Clock</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/pos-settings']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('POS_SETTINGS_MODULE') && permission?.hasPermission('ACCESS', 'POS_SETTINGS')"  (click)="onOrdersClick('/home/pos-settings')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">tune</mat-icon>
        <span class="google-font ml-1 item-name text-sm" *ngIf="opened">POS Settings</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/settlements']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('SETTLEMENTS_MODULE') && permission?.hasPermission('ACCESS', 'SETTLEMENTS')"  (click)="onOrdersClick('/home/settlements')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">summarize</mat-icon>
        <span class="google-font ml-1 item-name text-sm" *ngIf="opened">Settlements</span>
      </a>
      <a class="ml-1" mat-list-item [routerLink]="['/home/support']" routerLinkActive="is-active" *ngIf="store?.modules?.includes('SUPPORT_MODULE') && permission?.hasPermission('ACCESS', 'SUPPORT')"  (click)="onOrdersClick('/home/support')">
        <mat-icon matListItemIcon class="material-icons-outlined app-nav-list-icon">help</mat-icon><span
          class="google-font ml-1 item-name text-sm" *ngIf="opened">Support</span>
      </a>
    </mat-nav-list>
  </div>
</div>
