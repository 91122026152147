import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class POSService {
  endPoint = environment.apiUrl;
  paymentEndpoint = `${this.endPoint}/backend/payment`;
  validUserPin = false;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  public drawerEndLogOutuser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public posMenuHeight: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public scrolltoTop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);



  constructor(private http: HttpClient) {}

  posAuthorize(terminalId, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/${terminalId}/authorize/`,
      payload,
      this.httpOptions
    );
  }

  posAuthorizeManual(terminalId, payload, optIn) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/${terminalId}/authorize/?opt_in=${optIn}&is_open_tab=true`,
      payload,
      this.httpOptions
    );
  }

  posStatus(terminalId, payload) {
    return this.http.post<any>(
      `${environment.apiUrl}/backend/v2/pos/${terminalId}/status/`,
      payload,
      this.httpOptions
    );
  }

  getQuickEntryOrder(storeId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/pos/${storeId}/get_quick_order/`,
      this.httpOptions
    );
  }

  openCashDrawer(terminalId) {
    return this.http.get<any>(
      `${environment.apiUrl}/backend/v2/pos/${terminalId}/open_cash_drawer/`,
      this.httpOptions
    );
  }

  posAdjustment(terminalId, orderHash, adjustAmount) {
    var payload = {
      order_hash: orderHash,
      adjust_amount: adjustAmount,
    };
    return this.http.post<any>(
      `${this.endPoint}/backend/v2/pos/${terminalId}/adjust/`,
      payload,
      this.httpOptions
    );
  }

  posRefund(terminalId, orderHash, amount) {
    var payload = {
      order_hash: orderHash,
      amount: amount,
    };
    return this.http.post<any>(
      `${this.endPoint}/backend/v2/pos/${terminalId}/refund/`,
      payload,
      this.httpOptions
    );
  }

  posAddTip(terminalId, orderHash, amount) {
    var payload = {
      order_hash: orderHash,
      amount: amount,
    };
    return this.http.post<any>(
      `${this.endPoint}/backend/v2/pos/${terminalId}/add_tip/`,
      payload,
      this.httpOptions
    );
  }

  getPosLinkLogs(storeId) {
    return this.http.get<any>(`${this.endPoint}/backend/v2/link/logs/${storeId}/`, this.httpOptions);
  }

  /* DRAWER MANAGEMENT API's */
  getManageDrawerStatus(storeId, terminalId, userId) {
    const params = new HttpParams()
    .set('store_id', storeId)
    .set('terminal_id', terminalId)
    .set('user_id', userId)
    return this.http.get<any>(`${this.endPoint}/backend/v2/drawer-manage/${storeId}/`,{ params })
  }

  createDrawer(payload) {
    return this.http.post<any>(`${this.endPoint}/backend/v2/drawer-manage/`,payload, this.httpOptions)
  }

  addDrawerDenominations(storeId, payload) {
    return this.http.post<any>(`${this.endPoint}/backend/v2/drawer-manage/${storeId}/add_denominations/`,payload, this.httpOptions)
  }

  doingPayCashInOut(storeId, payload) {
    return this.http.post<any>(`${this.endPoint}/backend/v2/drawer-manage/${storeId}/cash_pay_in_and_out/`,payload, this.httpOptions)
  }

  endDrawer(storeId, drawerID, payload) {
    return this.http.post<any>(`${this.endPoint}/backend/v2/drawer-manage/${drawerID}/end_drawer/`,payload, this.httpOptions)
  }

  endDrawerDetails(storeId, drawerID) {
    const params = new HttpParams()
    .set('store_id', storeId)
    return this.http.get<any>(`${this.endPoint}/backend/v2/drawer-manage/${drawerID}/end_drawer_detail/`,{ params })

  }

  getCashLogDetails(storeId, terminal_id, start_date, end_date, from, to, page_size, page) {
    let params = new HttpParams()
    .set('terminal_id', terminal_id)
    .set("start_date", moment(start_date).format("YYYY-MM-DD"))
    .set("end_date", moment(end_date).format("YYYY-MM-DD"))
    .set("from_hour", from.hour)
    .set("from_min", from.min)
    .set("to_hour", to.hour)
    .set("to_min", to.min)
    .set('page_size', page_size)
    .set('page', page)
    
    return this.http.get<any>(`${this.endPoint}/backend/v2/drawer-manage/${storeId}/cash_drawer_management_table_data`,{ params })
  }
  
  cancelBackOrder(orderHash) {
    return this.http.post<any>(`${this.endPoint}/backend/v2/pos/${orderHash}/cancel_back_order/`, this.httpOptions)
  }
}
