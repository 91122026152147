<div class="search-form mod-group">
  <div class="search-items pull-left">
    <mat-form-field class="search-form-field" appearance="fill">
      <mat-label>Search Items</mat-label>
      <input matInput type="text" [(ngModel)]="searchTerm" (keydown)="search($event.value)">
      <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''; search('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="availability pull-left">
    <mat-form-field class="search-filter" appearance="fill">
      <mat-label>Availability</mat-label>
      <mat-select [(ngModel)]="availabilityFilter" (selectionChange)="search($event.value)">
        <mat-option value="all">All</mat-option>
        <mat-option value="unavailable">Unavailable</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="clear"></div>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="searchTerm || availabilityFilter; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>

  <mat-accordion>
    <ng-container *ngFor="let modGroup of searchResults">
        <mat-expansion-panel  #panel (opened)="onPanelOpen(panel, modGroup)" (closed)="onPanelClose(modGroup)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{modGroup.name}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-modifiers *ngIf="openedPanel === modGroup"
                  [modifierGroup]="modGroup"
                  [store]="store"
                  (modGroupUpdate)="setModGroup($event)"
                  (refresh)="refreshModGroup()"
                  [availability]="filterValue"
                ></app-modifiers>
            </ng-template>
          </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

</ng-template>

<ng-template #elseBlock>
  <mat-accordion cdkDropList [cdkDropListData]="orderedMgIds" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let mgid of orderedMgIds">
        <mat-expansion-panel cdkDrag [cdkDragStartDelay]="isTouchDevice ? 100 : 0" class="no-select" #panel (opened)="onPanelOpen(panel, modGroupOrdered[mgid])" (closed)="onPanelClose(modGroupOrdered[mgid])">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <!-- <span class="relative top-[2px] right-[2px]"><mat-icon>drag_indicator</mat-icon></span> -->
                <span cdkDragHandle class="cursor-move relative top-[2px] right-[2px]">
                  <mat-icon>drag_indicator</mat-icon>
                </span>
                {{modGroupOrdered[mgid].name}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-modifiers *ngIf="openedPanel === modGroupOrdered[mgid]"
                  [modifierGroup]="modGroupOrdered[mgid]"
                  [store]="store"
                  (modGroupUpdate)="setModGroup($event)"
                  (refresh)="refreshModGroup()"
                  [availability]="filterValue"
                ></app-modifiers>
            </ng-template>
          </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

  <br/>
  <app-add-new-modifier-group [store]="store" (refresh)="ngOnInit()"></app-add-new-modifier-group>
</ng-template>

