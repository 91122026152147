import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivateGiftCardDialogComponent } from '../activate-gift-card-dialog/activate-gift-card-dialog.component';
import { StoreService } from 'src/app/_services/store.service';
import { Subject } from 'rxjs';
import { OrdersWithPayment } from 'src/app/_models/order';
import { RefreshService } from 'src/app/_services/refresh.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-purchase-gift-card-dialog',
  templateUrl: './purchase-gift-card-dialog.component.html',
})
export class PurchaseGiftCardDialogComponent {
  purchaseGiftCardForm: FormGroup;
  private destroySubject: Subject<void> = new Subject();
  @Input() order: OrdersWithPayment;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PurchaseGiftCardDialogComponent>,
    private storeService: StoreService,
    private refreshService: RefreshService,
    private posSummaryToggleService: POSSummaryToggleService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.purchaseGiftCardForm = this.fb.group({
      amount: [
        '',
        [Validators.required, Validators.min(5), Validators.max(500)],
      ],
      cardType: ['', Validators.required],
    });
  }
  get amount() {
    return this.purchaseGiftCardForm.get('amount');
  }
  get cardType() {
    return this.purchaseGiftCardForm.get('cardType');
  }
  onCancel() {
    this.dialogRef.close(false);
  }
  formatAmount(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    const numericValue = Number(inputValue);
    const formattedValue = (numericValue / 100).toFixed(2);
    this.purchaseGiftCardForm.get('amount')?.setValue(formattedValue, { emitEvent: false });
  }
  onNext() {
    const formValue = this.purchaseGiftCardForm.value;

    console.log('this fffffffffffff', this.data);

    if (formValue.cardType === 'classic') {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(ActivateGiftCardDialogComponent, {
        width: isMobile ? '100vw' : '600px',
        data: { for: 'gift-card-purchase', amount: formValue.amount, store: this.data.order.store },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data) {
          const payload = {
            bot_order_id: this.data.order.bot_order.id,
            is_gift_card: true,
            card_type: result.cardDetails.card_type,
            items: [{ id: null, is_taxable: false, item_name: result.cardDetails.card_number, price: result.cardAmount, quantity: 1, is_gift_card: true }]
          };

          this.storeService.addMiscItems(payload).pipe(takeUntil(this.destroySubject)).subscribe(data => {
            this.posSummaryToggleService.toggle('OPEN')
            this.refreshService.refreshModule("POS_ORDER_SUMMARY");
            this.dialogRef.close(true);
          });
        }
      });
    } else {
      console.log('eGift Card flow - not implemented yet.');
    }
  }
}