<div class="p-8 bg-white rounded-lg shadow-md w-full max-w-[98%] sm:max-w-[95%] md:max-w-[850px] lg:max-w-[1100px]">
    <h2 class="text-2xl md:text-3xl font-medium text-gray-800">Purchase Gift Card</h2>
    <form [formGroup]="purchaseGiftCardForm" class="mt-8 space-y-8">
        <div>
            <label class="block text-lg font-medium text-gray-700" for="amount">Amount</label>
            <div class="flex items-center mt-3">
                <input id="amount" placeholder="0.00" formControlName="amount" (input)="formatAmount($event)"
                    class="w-full px-4 py-4 border border-gray-300 rounded-md text-lg focus:ring-indigo-500 focus:border-indigo-500" />
                <span class="ml-4 text-base text-gray-500 w-[150px]">$5.00 - $500.00</span>
            </div>
            <div class="h-5 mt-1 text-sm text-red-500">
                <span *ngIf="amount?.touched && amount?.errors?.['required']">Amount is required.</span>
                <span *ngIf="amount?.touched && amount?.errors?.['min']">Minimum amount is $5.00.</span>
                <span *ngIf="amount?.touched && amount?.errors?.['max']">Maximum amount is $500.00.</span>
            </div>
        </div>
        <div>
            <label class="block text-lg font-medium text-gray-700">Card Type:</label>
            <div class="flex items-center mt-4 space-x-6">
                <label class="flex items-center">
                    <input type="radio" name="cardType" value="classic" formControlName="cardType"
                        class="w-6 h-6 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                    <span class="ml-3 text-lg text-gray-700">Classic Gift Card</span>
                </label>
            </div>
            <div *ngIf="cardType?.invalid && (cardType?.touched || cardType?.dirty)" class="text-sm text-red-500 mt-1">
                <span *ngIf="cardType?.errors?.['required']">Card type is required.</span>
            </div>
        </div>
        <div class="flex justify-between mt-8 gap-4">
            <button type="button" (click)="onCancel()"
                class="w-1/2 px-4 py-6 text-lg font-medium text-gray-700 bg-gray-200 border border-gray-300 rounded-lg hover:bg-gray-300">
                Cancel
            </button>
            <button type="button" [disabled]="purchaseGiftCardForm.invalid" (click)="onNext()"
                class="w-1/2 px-4 py-6 text-lg font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed">
                Next
            </button>
        </div>
    </form>
</div>