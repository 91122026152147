<div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div class="w-96 bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-xl text-blue-600 text-center mb-4">
            Balance Inquiry Results
        </h2>
        <div *ngIf="isLoading" class="text-center text-gray-500">
            Loading balance...
        </div>
        <div *ngIf="!isLoading && balanceData" class="flex justify-between items-center text-lg text-gray-800 mb-6">
            <span>Card Balance</span>
            <span class="text-green-600">${{ balanceData.balance | number: '1.2-2' }}</span>
        </div>
        <div *ngIf="!isLoading && !balanceData" class="text-center text-red-500">
            Unable to fetch balance. Please try again.
        </div>
        <div class="flex gap-4">
            <button
                class="w-1/2 px-6 py-4 text-lg font-medium text-white bg-blue-600 border border-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                type="button" (click)="onAddValue()">
                Add Balance
            </button>
            <button
                class="w-1/2 px-6 py-4 text-lg font-medium text-white bg-blue-600 border border-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                type="button" (click)="onOk()">
                OK
            </button>
        </div>
    </div>
</div>