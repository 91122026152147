import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {MenuPiechartComponent} from '../../insights/product-mix/menu-piechart/menu-piechart.component';
import {FilterDialogComponent} from './filter-dialog/filter-dialog.component';
import {MenuService} from 'src/app/_services/menu.service';
import {StoreService} from 'src/app/_services/store.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DatePipe, formatDate} from '@angular/common';
import * as moment from 'moment';
import {GrossGraphComponent} from '../../insights/product-mix/gross-graph/gross-graph.component';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-product-mix',
  templateUrl: './product-mix.component.html',
  styleUrls: ['./product-mix.component.scss'],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ProductMixComponent implements OnInit {

  @ViewChildren('innerTables') innerTables: QueryList<MatTable<MoreData>>;
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;
  resizeObserver: ResizeObserver;
  /* Menu Breakout OnLoad Start*/
  arrPieNull: any = {
    "numbers": [100],
    "colors": ["#E8EAED"]
  }
  /* Menu Breakout OnLoad End */



  /* LIne Chart Start */

  isShowPercent = true

  chartData = {
    type: 'LineChart',
    data: [

    ],
    myOptions: {
      legend: 'none',
      vAxis: {},
      hAxis: {
      },
      // series: {
      //   0: { targetAxisIndex: 0, },
      //   1: { targetAxisIndex: 1, }
      // },
      // vAxes: {
      //   0: { textPosition: 'none' },
      //   1: {}
      // },
      rect: { width: 220 },
      colors: [],

    },
  };

  /* Line Chart End */
  displayedColumnsProduct = ['menu', "item_sales_total",
    "item_sales_online",
    "item_sales_pos",
    "gross_sales_total",
    "gross_sales_online",
    "gross_sales_pos",
    "sales_mix_total",
    "sales_mix_online",
    "sales_mix_pos",
    "qty_mix_total",
    "qty_mix_online",
    "qty_mix_pos",
    "action"]

  diaplayFilterColumn = []
  dataSourceMix: any
  dataSourceMixTable: any;

  innerDisplayedColumns = [
    'menu', "item_sales_total",
    "item_sales_online",
    "item_sales_pos",
    "gross_sales_total",
    "gross_sales_online",
    "gross_sales_pos",
    "sales_mix_total",
    "sales_mix_online",
    "sales_mix_pos",
    "qty_mix_total",
    "qty_mix_online",
    "qty_mix_pos",
    "subaction"
  ];
  @ViewChild(MenuPiechartComponent,{static: false}) dChartComp: any;
  @ViewChild(GrossGraphComponent,{static: false}) vChartComp: any;
  expandedElement: PeriodicElement | null;
  progressValue: any;
  menuNAme: any;
  lineData: any;
  grossName: any;
  totalSold: any;
  totalSales: any;

  discountGrossSales: any;
  arrPie = {
    "numbers": [],
    "colors": [],
    "isUpdate": 0,
  }
  arrPieTotal = {
    "numbers": [],
    "colors": [],
    "isUpdate": 0,
  }
  mixData: any;
  grossSalesTotal: any;
  totalItemsSold: any;
  Daterange: UntypedFormGroup;
  dateData: any;
  ddsds = new Date();
  StartDate: string;
  EndDate: string;
  StartTime: any = '';
  EndTime: any = '';
  AllDate = 1;
  fromTime: string;
  toTime: string;
  strHour: any;
  strHour2: any;
  name = 'Angular';
  idStore: any;
  connectStartFrom: any;
  connectEndTo: any;
  chart: any;
  subMenuMIx: any;
  dataShow1: any;
  eeerw: any = [];
  MenuGroupName: any;
  SubMenu: any;
  MenuQuantity: any;
  itemGroupPrice: any;
  subQuantity: any;
  subTotalPrice: any;
  subSalesPercent: number;
  MenuTotalPrice: any;
  findItemValue: any;
  findSalesValue: any;
  grossPercent: any;
  quantityPercent: number;
  subQuantityPercent: number;
  status = [];
  onlineMenuQuantity: any;
  posMenuQuantity: any;
  posMenuTotalPrice: any;
  onlineMenuTotalPrice: any;
  posGrossPercent: number;
  onlineGrossPercent: number;
  posQtyPercent: number;
  onlineQtyPercent: number;
  posSubTotalPrice: any;
  onlineSubTotalPrice: any;
  posSubSalesPercent: number;
  onlineSubSalesPercent: number;
  posSubQuantity: any;
  onlineSubQuantity: any;
  posSubQtyPercent: number;
  onlineSubQtyPercent: number;

  daysData: any = [];
  daysPrice: any = [];
  firstMonth: any = [];
  firstMonthData: any = [];
  firstMonthColor: any = [];
  secondMonth: any = [];
  secondMonthData: any = [];
  // secondMonthColor: any = [];
  thirdMonth: any = [];
  thirdMonthData: any = [];
  // thirdMonthColor: any = [];
  fourthMonth: any = [];
  fourthMonthData: any = [];
  // fourthMonthColor: any = [];
  fifthMonth: any = [];
  fifthMonthData: any = [];
  // fifthMonthColor: any = [];
  newArrray = [];
  newJsonCsv: any = [];
  firtsTime = [];
  secondTime: any = [];
  thirdTime = [];
  allPush: any = [];
  exportTime
  maxDateCheck: string;
  isLoading:boolean = true;
  columnNames: any;

  onChangeHour(event) {
    const currentTime = new Date();
    const hours = event.hour;
    let minutes = event.minute;
    let seconds = currentTime.getSeconds();
    this.strHour = hours + ':' + minutes;
  }

  onChangeHour2(event) {
    const currentTime = new Date();
    const hours = event.hour;
    let minutes = event.minute;
    let seconds = currentTime.getSeconds();
    this.strHour2 = hours + ':' + minutes;
  }
  constructor(public dialog: MatDialog, public storeService: StoreService, public menuService: MenuService,
              private _formBuilder: UntypedFormBuilder, private datePipe: DatePipe) {

    this.EndDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    var startdate = moment().subtract(6, "days").format("YYYY-MM-DD");
    this.StartDate = startdate;
    this.connectStartFrom = this.StartDate;
    this.connectEndTo = this.EndDate;

    /* Max Date Limit  */
    this.maxDateCheck = moment().subtract(18, "months").format("YYYY-MM-DD");
  }

  ngOnInit() {
    this.idStore = localStorage.getItem('currentStore');
    this.donutMenu(0);
    this.donutSales(0);
    this.productMix();
    this.lineChart();
    this.Daterange = this._formBuilder.group({
      from_date: [this.StartDate],
      to_date: [this.EndDate],
      from_time: [''],
      to_time: ['']

    })
    this.manageTableFilters();

  }

  ngAfterViewInit() {
    this.setupResizeObserver();
  }
  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
  setupResizeObserver() {
    if (this.chartContainer && this.chartContainer.nativeElement) {
      this.resizeObserver = new ResizeObserver(() => this.redrawChart());
      this.resizeObserver.observe(this.chartContainer.nativeElement);
    }
  }
  redrawChart() {
      // Get the current chart data and column names
      const dataCopy = [...this.chartData.data];
      const columnNamesCopy = [...this.columnNames];
      // Clear the chart data temporarily
      this.chartData.data = [];
      this.columnNames = [];
      // Use a slight delay to reapply the data to allow for proper re-initialization
      setTimeout(() => {
        // Restore the chart data and column names
        this.chartData.data = dataCopy;
        this.columnNames = columnNamesCopy;
      }, 50); // Adjust timing if necessary
  }


  manageTableFilters() {
    this.displayedColumnsProduct = ['menu']
    this.innerDisplayedColumns = ['menu']
    this.menuService.getManageFilters(this.idStore).subscribe((data: any) => {
      this.status = data.data
      if (this.status[0].item_sales_total == true) {
        this.displayedColumnsProduct.push('item_sales_total')
        this.innerDisplayedColumns.push('item_sales_total')
      }
      if (this.status[0].item_sales_online == true) {
        this.displayedColumnsProduct.push('item_sales_online')
        this.innerDisplayedColumns.push('item_sales_online')
      }
      if (this.status[0].item_sales_pos == true) {
        this.displayedColumnsProduct.push('item_sales_pos')
        this.innerDisplayedColumns.push('item_sales_pos')
      }


      if (this.status[0].gross_sales_total == true) {
        this.displayedColumnsProduct.push('gross_sales_total')
        this.innerDisplayedColumns.push('gross_sales_total')
      }
      if (this.status[0].gross_sales_online == true) {
        this.displayedColumnsProduct.push('gross_sales_online')
        this.innerDisplayedColumns.push('gross_sales_online')

      }
      if (this.status[0].gross_sales_pos == true) {
        this.displayedColumnsProduct.push('gross_sales_pos')
        this.innerDisplayedColumns.push('gross_sales_pos')

      }

      if (this.status[0].sales_mix_total == true) {
        this.displayedColumnsProduct.push('sales_mix_total')
        this.innerDisplayedColumns.push('sales_mix_total')

      }
      if (this.status[0].sales_mix_online == true) {
        this.displayedColumnsProduct.push('sales_mix_online')
        this.innerDisplayedColumns.push('sales_mix_online')

      }
      if (this.status[0].sales_mix_pos == true) {
        this.displayedColumnsProduct.push('sales_mix_pos')
        this.innerDisplayedColumns.push('sales_mix_pos')

      }

      if (this.status[0].qty_mix_total == true) {
        this.displayedColumnsProduct.push('qty_mix_total')
        this.innerDisplayedColumns.push('qty_mix_total')

      }
      if (this.status[0].qty_mix_online == true) {
        this.displayedColumnsProduct.push('qty_mix_online')
        this.innerDisplayedColumns.push('qty_mix_online')

      }
      if (this.status[0].item_sales_pos == true) {
        this.displayedColumnsProduct.push('qty_mix_pos')
        this.innerDisplayedColumns.push('qty_mix_pos')

      }
      this.displayedColumnsProduct.push('action')
      this.innerDisplayedColumns.push('subaction')

    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      panelClass:'filter-size',
      data: this.status,
      height: '500px',
      // width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.manageTableFilters()
    });
  }

  filterSubmit() {
    this.arrPie = {
      "numbers": [],
      "colors": [],
      "isUpdate": 1
    }
    this.arrPieTotal = {
      "numbers": [],
      "colors": [],
      "isUpdate": 1
    }
    this.dateData = {
      success: true,
      StartDate: this.datePipe.transform(this.Daterange.value.from_date, 'yyyy-MM-dd'),
      EndDate: this.datePipe.transform(this.Daterange.value.to_date, 'yyyy-MM-dd'),
      StartTime: this.strHour,
      EndTime: this.strHour2,
    }
    this.StartDate = this.dateData.StartDate,
    this.EndDate = this.dateData.EndDate,
    this.StartTime = this.dateData.StartTime,
    this.EndTime =  this.dateData.EndTime
    if (this.strHour == undefined && this.strHour2 != undefined) {
      this.connectStartFrom = this.StartDate;
      this.connectEndTo = this.EndDate;
      this.StartTime = '';
      this.EndTime = this.dateData.EndTime;
    }
    if (this.strHour2 == undefined && this.strHour != undefined) {
      this.connectStartFrom = this.StartDate;
      this.connectEndTo = this.EndDate;
      this.StartTime = this.dateData.StartTime;
      this.EndTime = '';
    }
    if (this.strHour == undefined && this.strHour2 == undefined) {
      this.connectStartFrom = this.StartDate;
      this.connectEndTo = this.EndDate;
      this.StartTime = '';
      this.EndTime = '';
    }
    this.eeerw = []
    this.donutMenu(1);
    this.donutSales(1);
    this.productMix();
    // this.lineChart();
  }

  /* Menu Breakout Donut Start */
  donutMenu(number) {
    this.isLoading = true;
    let keySend = {
      start_date: this.connectStartFrom,
      end_date: this.connectEndTo,
      start_time: this.StartTime,
      end_time: this.EndTime
    };
    this.menuService.getMenuChartGraph(this.idStore, keySend).subscribe((response) => {
      let groupnName: any = [];
      groupnName = response;
      this.totalSold = groupnName.total_items_sold;
      this.menuNAme = groupnName.data
      let valueee = this.menuNAme.map(item => {
        item.quantity_by_sub_cat_b
        let percent = Math.round(((item.quantity_by_sub_cat_b * 100) / this.totalSold))
        item.percent = percent;
        return item;
      });
      this.menuNAme = valueee;
      this.menuGraph(number)
      this.isLoading = false;
    });
  }

  menuGraph(number) {
    this.menuNAme.map(data => {
      this.arrPie.numbers.push(data.percent)
      this.arrPie.colors.push(data.color)
      this.arrPie.isUpdate = number
    })
    this.menuService.setRefresh(this.arrPie);

  }

  /* Menu Breakout Donut End */

  /* Gross Sales Donut Start */
  donutSales(number) {
    this.isLoading = true;
    let keySend = {
      start_date: this.connectStartFrom,
      end_date: this.connectEndTo,
      start_time: this.StartTime,
      end_time: this.EndTime
    };
    this.menuService.getGrossChartGraph(this.idStore, keySend).subscribe((response) => {
      let grossSales: any = [];
      grossSales = response;
      this.discountGrossSales = grossSales.total_gross_main;
      this.totalSales = grossSales.gross_sales_total;
      this.grossName = grossSales.data
      let valueGross = this.grossName.map(item => {
        item.total_price
        let percentGross = Math.round(((item.total_price * 100) / this.totalSales))
        item.percent = percentGross;
        return item;
      });
      this.grossName = valueGross;
      this.grossGraph(number)
      this.isLoading = false;
    });
  }

  grossGraph(number) {
    this.grossName.map(data => {
      this.arrPieTotal.numbers.push(data.percent)
      this.arrPieTotal.colors.push(data.color)
      this.arrPieTotal.isUpdate = number
    })
    this.menuService.setRefresh1(this.arrPieTotal);
  }
  lineChart() {
    this.daysData = [];
    let keySend = {
      // start_date: "2022-03-02",
      // end_date: "2022-04-02",
      start_date: this.StartDate,
      end_date: this.EndDate,
    };
    this.menuService.getLineChartGraph(this.idStore,keySend).subscribe((response: any) => {
      this.lineData = response.data[0].data;
      let a = [];
      a = response.data.map(item=>{
        let c=[];
        let colour=[];
        c.push(item.month)
        item.data.map(b=>{
          c.push(Math.round(b.total_price))
          colour.push(b.color)
          if (c.length == item.data.length) {
            this.daysData.push(c)
            this.firstMonthColor.push(colour)
          }
        })
      })
    });
    setTimeout(() => {
      this.chartData = {
        type: 'LineChart',
        data: this.daysData,
        myOptions: {
          legend: 'none',
          vAxis: {},
          hAxis: {
          },
          rect: { width: 220 },
          colors: this.firstMonthColor[0],
        },
      };

    }, 6000);

  }
  /* Gross Sales Donut End */

  productMix() {
    this.isLoading = true;
    let keySend = {
      start_date: this.connectStartFrom,
      end_date: this.connectEndTo,
      start_time: this.StartTime,
      end_time: this.EndTime
    };
    this.menuService.getProductMixTable(this.idStore, keySend).subscribe((response) => {
      let allSalesData: any = [];
      allSalesData = response
      this.grossSalesTotal = allSalesData.gross_sales_total
      this.totalItemsSold = allSalesData.total_items_sold
      this.mixData = allSalesData.data
      this.dataSourceMix = this.mixData
      this.findSalesValue = this.mixData.map(item => {
        item.total_price
        let grossPercent = Math.round(((item.total_price * 100) / this.grossSalesTotal))
        item.salesPercent = grossPercent;
        return item;
      })
      this.mixData = this.findSalesValue
      this.findItemValue = this.mixData.map(item => {
        item.quantity_by_sub_cat_b
        let itemPercent = Math.round(((item.quantity_by_sub_cat_b * 100) / this.totalItemsSold))
        item.itempercent = itemPercent;
        return item
      })
      this.mixData = this.findItemValue
      this.dataSourceMixTable = new MatTableDataSource<PeriodicElement>(this.mixData)
      this.tableDataMix();

    });
  }
  tableDataMix() {
    for (let index = 0; index < this.mixData.length; index++) {
      this.MenuGroupName = this.mixData[index].menu_group_name;
      this.dataShow1 = this.mixData[index].menu_item_group_data;
      /* Menu Quantity */
      this.MenuQuantity = this.mixData[index].quantity_by_sub_cat_b;
      this.posMenuQuantity = this.mixData[index].pos_quantity_by_sub_cat_b;
      this.onlineMenuQuantity = this.mixData[index].online_quantity_by_sub_cat_b;
      /* Menu Price */
      this.MenuTotalPrice = this.mixData[index].total_price;
      this.posMenuTotalPrice = this.mixData[index].pos_total_price;
      this.onlineMenuTotalPrice = this.mixData[index].online_total_price;
      /* Menu Sales Percent */
      this.grossPercent = Math.round(((this.MenuTotalPrice * 100) / this.grossSalesTotal));
      this.posGrossPercent = Math.round(((this.posMenuTotalPrice * 100) / this.grossSalesTotal));
      this.onlineGrossPercent = Math.round(((this.onlineMenuTotalPrice * 100) / this.grossSalesTotal));
      /* Menu Quantity Percent */
      this.quantityPercent = Math.round(((this.MenuQuantity * 100) / this.totalItemsSold));
      this.posQtyPercent = Math.round(((this.posMenuQuantity * 100) / this.totalItemsSold));
      this.onlineQtyPercent = Math.round(((this.onlineMenuQuantity * 100) / this.totalItemsSold));

      let as = []
      for (let index1 = 0; index1 < this.dataShow1.length; index1++) {
        this.SubMenu = this.dataShow1[index1].menu_item_group_name;
        this.itemGroupPrice = this.dataShow1[index1].menu_item_group_starting_price;
        /* Sub Menu Quantity */
        this.subQuantity = this.dataShow1[index1].quantity_by_sub_cat_a;
        this.posSubQuantity = this.dataShow1[index1].pos_quantity_by_sub_cat_a;
        this.onlineSubQuantity = this.dataShow1[index1].online_quantity_by_sub_cat_a;
        /* Sub Menu Price */
        this.subTotalPrice = this.dataShow1[index1].total_price_sub;
        this.posSubTotalPrice = this.dataShow1[index1].pos_total_price_sub;
        this.onlineSubTotalPrice = this.dataShow1[index1].online_total_price_sub;
        /* Sub Menu Sales Percent */
        if (this.MenuTotalPrice == '0.00') {
          this.subSalesPercent = 0;
        } else {
          this.subSalesPercent = Math.round(((this.subTotalPrice * 100) / this.MenuTotalPrice));
        }
        if (this.MenuTotalPrice == '0.00') {
          this.posSubSalesPercent = 0;
        } else {
          this.posSubSalesPercent = Math.round(((this.posSubTotalPrice * 100) / this.MenuTotalPrice));
        }
        if (this.MenuTotalPrice == '0.00') {
          this.onlineSubSalesPercent = 0;
        } else {
          this.onlineSubSalesPercent = Math.round(((this.onlineSubTotalPrice * 100) / this.MenuTotalPrice));
        }
        /* Sub Menu Quantity Percent */
        if (this.MenuQuantity == 0) {
          this.subQuantityPercent = 0;
        } else {
          this.subQuantityPercent = Math.round(((this.subQuantity * 100) / this.MenuQuantity));
        }
        if (this.MenuQuantity == 0) {
          this.posSubQtyPercent = 0;
        } else {
          this.posSubQtyPercent = Math.round(((this.posSubQuantity * 100) / this.MenuQuantity));
        }
        if (this.MenuQuantity == 0) {
          this.onlineSubQtyPercent = 0;
        } else {
          this.onlineSubQtyPercent = Math.round(((this.onlineSubQuantity * 100) / this.MenuQuantity));
        }
        const ree = {
          "menu_item_group_name": this.SubMenu,
          "menu_item_group_starting_price": this.itemGroupPrice,

          "quantity_by_sub_cat_a": this.subQuantity,
          "pos_quantity_by_sub_cat_a": this.posSubQuantity,
          "online_quantity_by_sub_cat_a": this.onlineSubQuantity,

          "total_price_sub": this.subTotalPrice,
          "pos_total_price_sub": this.posSubTotalPrice,
          "online_total_price_sub": this.onlineSubTotalPrice,

          "sub_sales_percent": this.subSalesPercent,
          "pos_sub_sales_percent": this.posSubSalesPercent,
          "online_sub_sales_perent": this.onlineSubSalesPercent,

          "sub_qtyty_percent": this.subQuantityPercent,
          "pos_sub_qty_percent": this.posSubQtyPercent,
          "online_sub_qty_percent": this.onlineSubQtyPercent
        }
        as.push(ree)
      }
      const sss = {
        "menu_group_name": this.MenuGroupName,
        "menu_item_group_data": as,

        "quantity_by_sub_cat_b": this.MenuQuantity,
        "pos_quantity_by_sub_cat_b": this.posMenuQuantity,
        "online_quantity_by_sub_cat_b": this.onlineMenuQuantity,

        "total_price": this.MenuTotalPrice,
        "pos_total_price": this.posMenuTotalPrice,
        "online_total_price": this.onlineMenuTotalPrice,

        "sales_per": this.grossPercent,
        "pos_sales_per": this.posGrossPercent,
        "online_sales_per": this.onlineGrossPercent,

        "quantity_per": this.quantityPercent,
        "pos_quantity_per": this.posQtyPercent,
        "online_quantity_per": this.onlineQtyPercent
      }
      this.eeerw.push(sss)
      this.dataSourceMixTable = this.eeerw
      this.isLoading = false;

    }
    this.csvFileDownload();
  }

  csvFileDownload() {
    let keySend = {
      data: this.dataSourceMixTable
    }
    this.menuService.downloadCsvFile(keySend).subscribe((response: any) => {
      this.newJsonCsv = response.data
    });

  }

  downloadFile(data, filename = 'Product-Mix-Data') {
    let csvData = this.ConvertToCSV(data, ['Menu',
      'Sub_Menu',
      'Sub_Menu_Quantity',
      'Sub_Menu_Quantity_POS',
      'Sub_Menu_Quantity_Online',
      'Sub_Menu_Total_Price',
      'Sub_Menu_Total_Price_POS',
      'Sub_Menu_Total_Price_Online',
      'Sub_Menu_Sales_Percent',
      'Sub_Menu_Sales_Percent_POS',
      'Sub_Menu_Sales_Percent_Online',
      'Sub_Menu_Quantity_Percent',
      'Sub_Menu_Quantity_Percent_POS',
      'Sub_Menu_Quantity_Percent_Online',
      'Menu_Quantity',
      'Menu_Quantity_POS',
      'Menu_Quantity_Online',
      'Menu_Total_Price',
      'Menu_Total_Price_POS',
      'Menu_Total_Price_Online',
      'Menu_Sales_Percent',
      'Menu_Sales_Percent_POS',
      'Menu_Sales_Percent_Online',
      'Menu_Quantity_Percent',
      'Menu_Quantity_Percent_POS',
      'Menu_Quantity_Percent_Online']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
  download() {
    this.downloadFile(this.newJsonCsv);
  }
}
export interface PeriodicElement {
  menu: string;
  total_items_sold_online: number;
  total_items_sold: number;
  total_items_sold_pos: string;
  total_gross_sales: string;
  total_sales_mix_precentage: string;
  total_qty_mix_precentage: string;
  more_data: MoreData[] | MatTableDataSource<MoreData>;
}

export interface MoreData {
  menu: string;
  total_items_sold_online: number;
  total_items_sold: number;
  total_items_sold_pos: string;
  total_gross_sales: string;
  total_sales_mix_precentage: string;
  total_qty_mix_precentage: string;
}
