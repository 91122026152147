<div fxLayout="column" fxFlex="100" fxLayoutAlign="start">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <!-- <form [formGroup]="form" class="flex flex-wrap order-message-table-filters" *ngIf="formAvailable"> -->
  <form [formGroup]="form" *ngIf="formAvailable">
    <div class="flex flex-wrap order-message-table-filters">
      <!-- <div class="start-datetime"> -->
      <mat-form-field appearance="fill" class="start start-field">
        <mat-label>Start Date</mat-label>
        <input formControlName="start_date" name="startDate" matInput [matDatepicker]="startPicker"
          placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="start end-field">
        <mat-label>From</mat-label>
        <mat-select formControlName="from" [compareWith]="hourCompare">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- </div>
    <div class="end-datetime"> -->
      <mat-form-field appearance="fill" class="start start-field">
        <mat-label>End Date</mat-label>
        <input formControlName="end_date" name="endDate" matInput [matDatepicker]="endPicker"
          placeholder="Choose a end date">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="start end-field">
        <mat-label>To</mat-label>
        <mat-select formControlName="to" [compareWith]="hourCompare">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- </div> -->
      <div class="" *ngIf="posHaveorNot">
        <mat-form-field appearance="fill" class="start station">
          <mat-label>Station</mat-label>
          <mat-select formControlName="station">
            <mat-option *ngFor="let terminal of terminalList" [value]="terminal">{{terminal.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="btn-reset-search">
        <button mat-flat-button color="primary" (click)="searchOrders()" class="action-btn-colr btn-search w-32 mr-5 !h-[50px]"
          [disabled]="isLoading">SEARCH</button>

        <button *ngIf="permissionPIN?.hasPermission('REPORTING', 'PERIOD_REPORT_MASTER')" mat-flat-button
          color="primary" (click)="searchOrdersAll()" class="action-btn-colr w-32 mr-5 !leading-[20px] !h-[50px]" [disabled]="isLoading">SEARCH
          ALL</button>

        <button mat-flat-button color="primary" (click)="openUserSelectDialog(true)"
          class="action-btn-colr btn-search w-32 mr-5 !h-[50px]" [disabled]="isLoading">EXPORT</button>

        <button *ngIf="permissionPIN?.hasPermission('REPORTING', 'PERIOD_REPORT_MASTER')" mat-flat-button
          color="primary" (click)="openUserSelectDialog(false)" class="action-btn-colr w-32 mr-5 !leading-[20px] !h-[50px]"
          [disabled]="isLoading">EXPORT
          ALL</button>

        <button mat-stroked-button color="primary" (click)="reset()" class="btn-reset w-32 !h-[50px]"
          [disabled]="isLoading">RESET</button>
      </div>
      <!-- <div class="clear"></div> -->
    </div>
  </form>
  <div *ngIf="is_error" class="text-center font-bold">
    Date range is outside user profile constraints.
  </div>
  <div *ngIf="!is_error">
    <div class="flex flex-wrap mx-2 gap-2 all-print-report" *ngIf="report">
      <!-- PERIOD REPORT -->
      <div *ngIf="permissionPIN?.hasPermission('REPORTING', 'VIEW_PERIOD_REPORT_MASTER')">
        <div
          *ngIf="store.getFeatureFlag('enable_report_printer_selection') && printers.length > 1; then multiBlock else singleBlock">
        </div>

        <ng-template #singleBlock>
          <div class="print-button-alignment">
            <button mat-flat-button color="primary" (click)="selectedSummaryPrint(1,null)"
              class="action-btn-colr action-button" [disabled]="isLoading" style="width: 35%;">PRINT</button>
          </div>
        </ng-template>

        <ng-template #multiBlock>
          <div class="print-button-alignment">
            <mat-form-field appearance="fill" class="">
              <mat-label>Printer</mat-label>
              <mat-select #toPrint1>
                <mat-option *ngFor="let printer of printers" [value]="printer">{{printer.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="selectedSummaryPrint(1, toPrint1.value )"
              class=" period-r-w action-btn-colr action-button bottom-2.5" [disabled]="isLoading"
              style="width: 35%;">PRINT</button>
          </div>
        </ng-template>

        <table class="table dark-bg-color" cellspacing=2 cellpadding=5 border="1">
          <tr class="center">
            <td colspan="2" style="font-size: 18px;">PERIOD REPORT</td>
          </tr>
          <tr class="center">
            <td colspan="2">{{report?.all.time.start_date | date:"shortDate"}} {{report?.all.time.from_time}} -
              {{report?.all.time.end_date |date:'shortDate'}} {{report?.all.time.to_time}}</td>
          </tr>
          <tr class="center border-bottom">
            <td colspan="2" class="pb-10">{{report?.all.time.created_at | date:'short'}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Sales Summary</td>
          </tr>
          <tr>
            <td>Gross Sales</td>
            <td class="align-right">{{report.all.gross_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.gross_sales_count || 0}})</td>
            <td class="align-right">{{report.on_premise.gross_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.gross_sales_count || 0}})</td>
            <td class="align-right">{{report.online.gross_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Discounts</td>
            <td class="align-right">{{report.all.discounts || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.discounts_count || 0}})</td>
            <td class="align-right">{{report.on_premise.discounts || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading" style="padding-left: 60px;">Instant Cash
              Discount({{report.on_premise.discounts_count
              || 0}})</td>
            <td class="align-right">{{report.on_premise.instant_cash_discount || 0 | currency:'USD'}}</td>
          </tr>
          <ng-container *ngFor="let discount of report.on_premise.discount_list">
            <tr *ngIf="store?.hasModule('POS')">
              <td class="sub-heading" style="padding-left: 60px;">{{ discount.discount_description }} ({{
                discount.instant_discount_description_count }})</td>
              <td class="align-right">{{ discount.instant_discounts || 0 | currency:'USD' }}</td>
            </tr>
          </ng-container>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.discounts_count || 0}})</td>
            <td class="align-right">{{report.online.discounts || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Net Sales</td>
            <td class="align-right">{{report.all.net_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.net_sales_count || 0}})</td>
            <td class="align-right">{{report.on_premise.net_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.net_sales_count || 0}})</td>
            <td class="align-right">{{report.online.net_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Non-Taxable Sales</td>
            <td class="align-right">{{report.all.non_taxable_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.non_taxable_sales_count || 0}})</td>
            <td class="align-right">{{report.on_premise.non_taxable_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.non_taxable_sales_count || 0}})</td>
            <td class="align-right">{{report.online.non_taxable_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Taxes</td>
            <td class="align-right">{{report.all.taxes || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.taxes_count || 0}})</td>
            <td class="align-right">{{report.on_premise.taxes || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading" style="padding-left: 90px;">Local</td>
            <td class="align-right">{{report.on_premise.local_tax || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading" style="padding-left: 90px;">State</td>
            <td class="align-right">{{report.on_premise.state_tax || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.taxes_count || 0}})</td>
            <td class="align-right">{{report.online.taxes || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Tips</td>
            <td class="align-right">{{report.all.tips || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.tips_count || 0}})</td>
            <td class="align-right">{{report.on_premise.tips || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.tips_count || 0}})</td>
            <td class="align-right">{{report.online.tips || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Delivery Fees</td>
            <td class="align-right">{{report.all.delivery_fees || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.delivery_fees_count || 0}})</td>
            <td class="align-right">{{report.on_premise.delivery_fees || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.delivery_fees_count || 0}})</td>
            <td class="align-right">{{report.online.delivery_fees || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Platform Charges</td>
            <td class="align-right">{{report.all.platform_charges || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.platform_charges_count || 0}})</td>
            <td class="align-right">{{report.on_premise.platform_charges || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.platform_charges_count || 0}})</td>
            <td class="align-right">{{report.online.platform_charges || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Voids ({{report.all.void_quantity || 0}})</td>
            <td class="align-right">{{report.all.void_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.void_quantity_count || 0}})</td>
            <td class="align-right">{{report.on_premise.void_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.void_quantity_count || 0}})</td>
            <td class="align-right">{{report.online.void_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Refunds ({{report.all.refund_quantity || 0}})</td>
            <td class="align-right">{{report.all.refund_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.refund_quantity || 0}})</td>
            <td class="align-right">{{report.on_premise.refund_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.refund_quantity || 0}})</td>
            <td class="align-right">{{report.online.refund_net_value || 0 | currency:'USD'}}</td>
          </tr>

          <tr>
            <td>Cancels ({{report.all.cancel_quantity || 0}})</td>
            <td class="align-right">{{report.all.cancel_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.cancel_quantity || 0}})</td>
            <td class="align-right">{{report.on_premise.cancel_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.cancel_quantity || 0}})</td>
            <td class="align-right">{{report.online.cancel_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Total Receipts</td>
            <td class="align-right">{{report.all.total_receipts || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.total_receipts_count || 0}})</td>
            <td class="align-right">{{report.on_premise.total_receipts || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.total_receipts_count || 0}})</td>
            <td class="align-right">{{report.online.total_receipts || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Average Ticket Value</td>
            <td class="align-right">{{report.all.average_ticket_value || 0 | currency:'USD' }}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise</td>
            <td class="align-right">{{report.on_premise.average_ticket_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')" class="border-bottom">
            <td class="sub-heading">Online</td>
            <td class="align-right">{{report.online.average_ticket_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Order Summary</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td>Total POS Orders({{report.on_premise.total_orders || 0 }})</td>
            <td class="align-right">{{report.on_premise.total_receipts || 0 | currency:'USD' }}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Pick Up({{report.on_premise.pickup_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.pickup_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Delivery({{report.on_premise.delivery_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.delivery_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Dine In({{report.on_premise.dinein_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.dinein_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Curbside({{report.on_premise.curbside_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.curbside_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Tableside({{report.on_premise.tableside_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.tableside_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">To-go({{report.on_premise.to_go_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.to_go_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">For-here({{report.on_premise.for_here_orders_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.for_here_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Total Online Orders({{report.online.total_orders || 0 }})</td>
            <td class="align-right">{{report.online.total_receipts || 0 | currency:'USD' }}</td>
          </tr>
          <tr>
            <td class="sub-heading">Pick Up({{report.online.pickup_orders_count || 0 }})</td>
            <td class="align-right">{{report.online.pickup_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="sub-heading">Delivery({{report.online.delivery_orders_count || 0 }})</td>
            <td class="align-right">{{report.online.delivery_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="sub-heading">Dine In({{report.online.dinein_orders_count || 0 }})</td>
            <td class="align-right">{{report.online.dinein_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="sub-heading">Curbside({{report.online.curbside_orders_count || 0 }})</td>
            <td class="align-right">{{report.online.curbside_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="sub-heading">Tableside({{report.online.tableside_orders_count || 0 }})</td>
            <td class="align-right">{{report.online.tableside_gross_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Payment Summary</td>
          </tr>
          <tr>
            <td>Cash({{report.all.cash_count || 0 }})</td>
            <td class="align-right">{{report.all.cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.cash_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.cash_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.net_cash_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.cash_count || 0 }})</td>
            <td class="align-right">{{report.online.cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.online.cash_refund_count || 0 }})</td>
            <td class="align-right">{{report.online.net_cash_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Card({{report.all.card_count || 0 }})</td>
            <td class="align-right">{{report.all.card || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.credit_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.card || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.card_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.net_card_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.credit_count || 0 }})</td>
            <td class="align-right">{{report.online.card || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.online.card_refund_count || 0 }})</td>
            <td class="align-right">{{report.online.net_card_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Credit Keyed({{report.on_premise.credit_keyed_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.credit_keyed || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.credit_keyed_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.credit_keyed || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.credit_keyed_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.credit_keyed_refund_net_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Split On Premise({{report.all.all_split_count || 0 }})</td>
            <td class="align-right">{{report.all.all_split || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Credit({{report.on_premise.split_credit_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.split_credit || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.all.split_credit_refund_count || 0 }})</td>
            <td class="align-right">{{report.all.split_credit_refund_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Cash({{report.on_premise.split_cash_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.split_cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.all.split_cash_refund_count || 0 }})</td>
            <td class="align-right">{{report.all.split_cash_refund_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Gift({{report.on_premise.split_gift_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.split_gift || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.all.split_gift_refund_count || 0 }})</td>
            <td class="align-right">{{report.all.split_gift_refund_value || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Non-Taxable Cash({{report.all.non_taxable_sale_cash_count || 0 }})</td>
            <td class="align-right">{{report.all.non_taxable_sale_cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.non_taxable_sale_cash_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.non_taxable_sale_cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.non_tax_cash_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.net_non_taxable_cash_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.non_taxable_sale_cash_count || 0 }})</td>
            <td class="align-right">{{report.online.non_taxable_sale_cash || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.online.non_tax_cash_refund_count || 0 }})</td>
            <td class="align-right">{{report.online.net_non_taxable_cash_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Non-Taxable Card({{report.all.non_taxable_sale_card_count || 0 }})</td>
            <td class="align-right">{{report.all.non_taxable_sale_card || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.non_taxable_sale_card_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.non_taxable_sale_card || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.non_tax_card_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.net_non_taxable_card_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.non_taxable_sale_card_count || 0 }})</td>
            <td class="align-right">{{report.online.non_taxable_sale_card || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.online.non_tax_card_refund_count || 0 }})</td>
            <td class="align-right">{{report.online.net_non_taxable_card_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Gift({{report.all.gift_count || 0 }})</td>
            <td class="align-right">{{report.all.gift || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.gift_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.gift || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.gift_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.net_gift_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.gift_count || 0 }})</td>
            <td class="align-right">{{report.online.gift || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.online.gift_refund_count || 0 }})</td>
            <td class="align-right">{{report.online.net_gift_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td>Others({{report.all.other_count || 0 }})</td>
            <td class="align-right">{{report.all.others || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">On Premise({{report.on_premise.other_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.others || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.on_premise.other_refund_count || 0 }})</td>
            <td class="align-right">{{report.on_premise.net_other_refund || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td class="sub-heading">Online({{report.online.other_count || 0 }})</td>
            <td class="align-right">{{report.online.others || 0 | currency:'USD'}}</td>
          </tr>
          <tr *ngIf="store?.hasModule('POS')">
            <td style="padding-left: 53px;">Refunds({{report.online.other_refund_count || 0 }})</td>
            <td class="align-right">{{report.online.net_other_refund || 0 | currency:'USD'}}</td>
          </tr>
        </table>
      </div>

      <!-- CATEGORY REPORT -->
      <div *ngIf="permissionPIN?.hasPermission('REPORTING', 'VIEW_CATEGORY_REPORT_MASTER')">
        <div
          *ngIf="store.getFeatureFlag('enable_report_printer_selection') && printers.length > 1; then multiBlock else singleBlock">
        </div>
        <ng-template #singleBlock>
          <div class="print-button-alignment">
            <button mat-flat-button color="primary" (click)="print(2,null)" class="action-btn-colr action-button"
              [disabled]="isLoading" style="width: 35%;">PRINT</button>
          </div>
        </ng-template>
        <ng-template #multiBlock>
          <div class="print-button-alignment">
            <mat-form-field appearance="fill" class="">
              <mat-label>Printer</mat-label>
              <mat-select #toPrint2>
                <mat-option *ngFor="let printer of printers" [value]="printer">{{printer.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="print(2, toPrint2.value)"
              class=" period-r-w action-btn-colr action-button bottom-2.5" [disabled]="isLoading"
              style="width: 35%;">PRINT</button>
          </div>
        </ng-template>

        <table class="table dark-bg-color" cellspacing=2 cellpadding=5 border="1">
          <tr class="center">
            <td colspan="2" style="font-size: 18px;">CATEGORY REPORT</td>
          </tr>
          <tr class="center">
            <td colspan="2">{{report?.all.time.start_date | date:"shortDate"}} {{report?.all.time.from_time}} -
              {{report?.all.time.end_date |date:'shortDate'}} {{report?.all.time.to_time}}</td>
          </tr>
          <tr class="center border-bottom">
            <td colspan="2" class="pb-10">{{report?.all.time.created_at | date:'short'}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Category </td>
          </tr>

          <!-- Static Data -->
          <ng-container *ngFor="let item of categoryReport">
            <tr>
              <td class="head">{{item.parent}}({{item.count}})</td>
              <td class="align-right head">{{item.sale_price || 0 | currency:'USD'}}</td>
            </tr>
            <ng-container *ngFor="let menu_category of item.display_name_list">
              <tr>
                <td style="padding-left: 12px;">{{menu_category.name}}({{menu_category.count}})</td>
                <td class="align-right">{{menu_category.sale_price || 0 | currency:'USD'}}</td>
              </tr>
              <ng-container *ngFor="let product_list of menu_category.product_list">
                <tr>
                  <td class="sub-heading">{{product_list.display_name}}({{product_list.count}})</td>
                  <td class="align-right">{{product_list.sale_price || 0 | currency:'USD'}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>

        </table>
      </div>

      <!-- LABOUR REPORT -->
      <div *ngIf="permissionPIN?.hasPermission('REPORTING', 'VIEW_LABOR_REPORT_MASTER') && totalEmpReport">
        <div
          *ngIf="store.getFeatureFlag('enable_report_printer_selection') && printers.length > 1; then multiBlock else singleBlock">
        </div>
        <ng-template #singleBlock>
          <div class="print-button-alignment">
            <button mat-flat-button color="primary" (click)="print(3,null)" class="action-btn-colr action-button"
              [disabled]="isLoading" style="width: 35%;">PRINT</button>
          </div>
        </ng-template>
        <ng-template #multiBlock>
          <div class="print-button-alignment">
            <mat-form-field appearance="fill" class="">
              <mat-label>Printer</mat-label>
              <mat-select #toPrint3>
                <mat-option *ngFor="let printer of printers" [value]="printer">{{printer.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="print(3, toPrint3.value)"
              class="period-r-w action-btn-colr action-button bottom-2.5" [disabled]="isLoading"
              style="width: 35%;">PRINT</button>
          </div>
        </ng-template>

        <table class="table dark-bg-color" cellspacing=2 cellpadding=5 border="1">
          <tr class="center">
            <td colspan="2" style="font-size: 18px;">LABOR REPORT</td>
          </tr>
          <tr class="center">
            <td colspan="2">{{report?.all.time.start_date | date:"shortDate"}} {{report?.all.time.from_time}} -
              {{report?.all.time.end_date |date:'shortDate'}} {{report?.all.time.to_time}}</td>
          </tr>
          <tr class="center border-bottom">
            <td colspan="2" class="pb-10">{{report?.all.time.created_at | date:'short'}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Labor Summary</td>
          </tr>

          <tr>
            <td class="td-inner">Total # of Orders</td>
            <td>{{totalEmpReport.total_orders || 0}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Labor Cost</td>
            <td>{{totalEmpReport.total_labor_cost || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Employee State Tax</td>
            <td>{{totalEmpReport.total_state_tax || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Employee Federal Tax</td>
            <td>{{totalEmpReport.total_federal_tax || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Labor %</td>
            <td>{{totalEmpReport.labor_cost_per || 0}}%</td>
          </tr>
          <tr>
            <td class="td-inner">Total # Credit Card Order</td>
            <td>{{totalEmpReport.total_credit_card_orders || 0}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Credit Card Sales</td>
            <td>{{totalEmpReport.credit_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total # Cash Orders</td>
            <td>{{totalEmpReport.total_cash_orders || 0}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Cash Sales</td>
            <td>{{totalEmpReport.cash_sales || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total # Credit Keyed Orders</td>
            <td>{{totalEmpReport.total_credit_keyed_orders}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Credit Keyed Sales</td>
            <td>{{totalEmpReport.keyed_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total # Others Orders</td>
            <td>{{totalEmpReport.total_others}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Others Sales</td>
            <td>{{totalEmpReport.others_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total # Gift Orders</td>
            <td>{{totalEmpReport.total_gift}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Gift Sales</td>
            <td>{{totalEmpReport.gift_sale || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner"># of Current Open Orders</td>
            <td>{{totalEmpReport.total_open_orders || 0}}</td>
          </tr>
          <tr>
            <td class="td-inner"># of Cancels/Voids</td>
            <td>{{totalEmpReport.void_count || 0}}</td>
          </tr>
          <tr>
            <td class="td-inner">Cancels/Voids Total</td>
            <td>{{totalEmpReport.void_total || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Tips</td>
            <td>{{totalEmpReport.tip || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Service Fees:</td>
            <td>{{totalEmpReport.service_fees || 0 | currency:'USD'}}</td>
          </tr>
          <tr>
            <td class="td-inner">Total Hours Worked</td>
            <td>{{totalEmpReport.total_hours || 0}}</td>
          </tr>
          <tr class="border-bottom">
            <td class="td-inner">Total Break Time</td>
            <td>{{totalEmpReport.break_time || 0}}</td>
          </tr>

          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Labor Detail</td>
          </tr>
          <ng-container *ngFor="let row of empReport">
            <tr>
              <td style="width: 88%;" class="td-inner">{{row.cashier_id}} <span
                  style="font-size: 8px;">{{row.role}}</span></td>
              <td (click)="row.isExpand = !row.isExpand" style="padding-left: 44px;">
                <mat-icon *ngIf="!row.isExpand">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="row.isExpand">keyboard_arrow_up</mat-icon>
              </td>
            </tr>
            <tr *ngIf="row.isExpand">
              <td colspan="4">
                <table class="table table-bordered table-border" style="width:300px !important">
                  <tr>
                    <td class="td-inner">Total # of Orders</td>
                    <td>{{row.total_orders}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Labor Cost</td>
                    <td>{{row.total_labor_cost || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Labor %</td>
                    <td>{{row.labor_cost_per || 0}}%</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Employee State Tax</td>
                    <td>{{row.total_state_tax || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Employee Federal Tax</td>
                    <td>{{row.total_federal_tax || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total # Credit Card Order</td>
                    <td>{{row.total_credit_card_orders}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Credit Card Sales</td>
                    <td>{{row.credit_sales || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total # Cash Orders</td>
                    <td>{{row.total_cash_orders}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Cash Sales</td>
                    <td>{{row.cash_sales || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total # Credit Keyed Orders</td>
                    <td>{{row.total_credit_keyed_orders}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Credit Keyed Sales</td>
                    <td>{{row.keyed_sale || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total # Others Orders</td>
                    <td>{{row.total_others}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Others Sales</td>
                    <td>{{row.others_sale || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total # Gift Orders</td>
                    <td>{{row.total_gift}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Gift Sales</td>
                    <td>{{row.gift_sale || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner"># of Current Open Orders</td>
                    <td>{{row.total_open_orders}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner"># of Cancels/Voids</td>
                    <td>{{row.void_count}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Cancels/Voids Total</td>
                    <td>{{row.void_total || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Tips</td>
                    <td>{{row.tip || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Service Fees:</td>
                    <td>{{row.service_fees || 0 | currency:'USD'}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Hours Worked</td>
                    <td style="width: 23%">{{row.total_hours}}</td>
                  </tr>
                  <tr>
                    <td class="td-inner">Total Break Time</td>
                    <td>{{row.break_time}}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>

      <!-- Food Cost Report -->
      <div *ngIf="permissionPIN?.hasPermission('REPORTING', 'VIEW_FOOD_COST_REPORT')">
        <div
          *ngIf="store.getFeatureFlag('enable_report_printer_selection') && printers.length > 1; then multiBlock else singleBlock">
        </div>
        <ng-template #singleBlock>
          <div class="print-button-alignment">
            <button mat-flat-button color="primary" (click)="print(4,null)" class="action-btn-colr action-button"
              [disabled]="isLoading" style="width: 35%;">PRINT</button>
          </div>
        </ng-template>
        <ng-template #multiBlock>
          <div class="print-button-alignment">
            <mat-form-field appearance="fill" class="">
              <mat-label>Printer</mat-label>
              <mat-select #toPrint4>
                <mat-option *ngFor="let printer of printers" [value]="printer">{{printer.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="print(4, toPrint4.value)"
              class="period-r-w action-btn-colr action-button bottom-2.5" [disabled]="isLoading"
              style="width: 35%;">PRINT</button>
          </div>
        </ng-template>

        <table class="table dark-bg-color" cellspacing=2 cellpadding=5 border="1">
          <tr class="center">
            <td colspan="2" style="font-size: 18px;">FOOD COST REPORT</td>
          </tr>
          <tr class="center">
            <td colspan="2">{{report?.all.time.start_date | date:"shortDate"}} {{report?.all.time.from_time}} -
              {{report?.all.time.end_date |date:'shortDate'}} {{report?.all.time.to_time}}</td>
          </tr>
          <tr class="center border-bottom">
            <td colspan="2" class="pb-10">{{report?.all.time.created_at | date:'short'}}</td>
          </tr>
          <tr>
            <td colspan="2" class="pt-10 pb-10 center summary-heading">Food Cost Summary</td>
          </tr>

          <ng-container *ngFor="let item of foodCostReport">
            <tr>
              <td class="head">{{item.parent}}({{item.count}})</td>
              <td class="align-right head">{{item.sale_price || 0 | currency:'USD'}}</td>
            </tr>
            <tr>
              <td class="head"></td>
              <td class="align-right head">{{item.percentage || 0}}%</td>
            </tr>
            <ng-container *ngFor="let menu_category of item.display_name_list">
              <tr>
                <td style="padding-left: 12px;">{{menu_category.name}}({{menu_category.count}})</td>
                <td class="align-right">{{menu_category.sale_price || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="head"></td>
                <td class="align-right head">{{menu_category.percentage || 0}}%</td>
              </tr>
              <ng-container *ngFor="let product_list of menu_category.product_list">
                <tr>
                  <td class="sub-heading">{{product_list.display_name}}({{product_list.count}})</td>
                  <td class="align-right">{{product_list.sale_price || 0 | currency:'USD'}}</td>
                </tr>
                <tr>
                  <td class="head"></td>
                  <td class="align-right head">{{product_list.percentage || 0}}%</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>

        </table>
      </div>

      <!-- Profitability Report -->
      <!-- Cash Drawer Report -->
      <div>
        <div>
          <div *ngIf="permissionPIN?.hasPermission('REPORTING', 'VIEW_CASH_DRAWER')">
            <div
              *ngIf="store.getFeatureFlag('enable_report_printer_selection') && printers.length > 1; then multiBlockProfit else singleBlockProfit">
            </div>
            <ng-template #singleBlockProfit>
              <div class="print-button-alignment">
                <button mat-flat-button color="primary" (click)="print(5,null)" class="action-btn-colr action-button"
                  [disabled]="isLoading" style="width: 35%;">PRINT</button>
              </div>
            </ng-template>
            <ng-template #multiBlockProfit>
              <div class="print-button-alignment">
                <mat-form-field appearance="fill" class="">
                  <mat-label>Printer</mat-label>
                  <mat-select #toPrint5>
                    <mat-option *ngFor="let printer of printers" [value]="printer">{{printer.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-flat-button color="primary" (click)="print(5, toPrint5.value)"
                  class=" period-r-w action-btn-colr action-button bottom-2.5" [disabled]="isLoading"
                  style="width: 35%;">PRINT</button>
              </div>
            </ng-template>

            <table class="table dark-bg-color" cellspacing=2 cellpadding=5 border="1">
              <tr class="center">
                <td colspan="2" style="font-size: 18px;">PROFITABILITY REPORT</td>
              </tr>
              <tr class="center">
                <td colspan="2">{{report?.all.time.start_date | date:"shortDate"}} {{report?.all.time.from_time}} -
                  {{report?.all.time.end_date |date:'shortDate'}} {{report?.all.time.to_time}}</td>
              </tr>
              <tr class="center border-bottom">
                <td colspan="2" class="pb-10">{{report?.all.time.created_at | date:'short'}}</td>
              </tr>
              <tr>
                <td colspan="2" class="pt-10 pb-10 center summary-heading">Profitability Summary</td>
              </tr>

              <tr>
                <td class="td-inner">Total Revenue</td>
                <td>{{profitabilityReport?.total_revenue || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="td-inner">Total Labor Cost</td>
                <td>{{profitabilityReport?.total_labor_cost || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="td-inner">Total Labor %</td>
                <td>{{profitabilityReport?.labor_cost_per || 0}}%</td>
              </tr>
              <tr>
                <td class="td-inner">Total Food Cost</td>
                <td>{{profitabilityReport?.total_food_cost || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="td-inner">Total Food Cost %</td>
                <td>{{profitabilityReport?.food_cost_per || 0}}%</td>
              </tr>
              <tr>
                <td class="td-inner">Profit/Loss</td>
                <td>{{profitabilityReport?.profit_loss || 0 | currency:'USD'}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div *ngIf="permissionPIN?.hasPermission('REPORTING', 'VIEW_CASH_DRAWER') && cashDrawerReport">
            <div
              *ngIf="store.getFeatureFlag('enable_report_printer_selection') && printers.length > 1; then multiBlocksProfit else singleBlocksProfit">
            </div>
            <ng-template #singleBlocksProfit>
              <div class="print-button-alignment">
                <button mat-flat-button color="primary" (click)="print(6,null)" class="action-btn-colr action-button"
                  [disabled]="isLoading" style="width: 35%;">PRINT</button>
              </div>
            </ng-template>
            <ng-template #multiBlocksProfit>
              <div class="print-button-alignment">
                <mat-form-field appearance="fill" class="">
                  <mat-label>Printer</mat-label>
                  <mat-select #toPrint6>
                    <mat-option *ngFor="let printer of printers" [value]="printer">{{printer.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-flat-button color="primary" (click)="print(6, toPrint6.value)"
                  class=" period-r-w action-btn-colr action-button bottom-2.5" [disabled]="isLoading"
                  style="width: 35%;">PRINT</button>
              </div>
            </ng-template>

            <table class="table dark-bg-color" cellspacing=2 cellpadding=5 border="1">
              <tr class="center">
                <td colspan="2" style="font-size: 18px;">CASH DRAWER REPORT</td>
              </tr>
              <tr class="center">
                <td colspan="2">{{cashDrawerReport?.time.start_date | date:"shortDate"}}
                  {{cashDrawerReport.time.from_time}} -
                  {{cashDrawerReport.time.end_date |date:'shortDate'}} {{cashDrawerReport.time.to_time}}</td>
              </tr>
              <tr class="center">
                <td colspan="2" class="pb-10">{{cashDrawerReport?.time.created_at | date:'short'}}</td>
              </tr>
              <tr>
                <td>Starting Cash:</td>
                <td class="align-right">{{ cashDrawerReport?.starting_cash || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Total Paid In:</td>
                <td class="align-right">{{ cashDrawerReport?.total_paid_in || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Miscellaneous</td>
                <td class="align-right">{{ cashDrawerReport?.total_misc | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Change</td>
                <td class="align-right">{{ cashDrawerReport?.total_change | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Total Paid Out:</td>
                <td class="align-right">{{ cashDrawerReport?.total_paid_out || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Tips</td>
                <td class="align-right">{{ cashDrawerReport?.total_tips | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Others</td>
                <td class="align-right">{{ cashDrawerReport?.total_others | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Cash Payment Summary({{cashDrawerReport?.total_cash_count || 0}})</td>
                <td class="align-right">{{ cashDrawerReport?.total_cash || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">On Premise({{cashDrawerReport?.pos_cash_count || 0}})</td>
                <td class="align-right">{{ cashDrawerReport?.pos_cash || 0 | currency:'USD'}}</td>
              </tr>
              <tr class="border-bottom">
                <td class="sub-heading">Online({{cashDrawerReport?.online_cash_count || 0}})</td>
                <td class="align-right">{{ cashDrawerReport?.online_cash || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Expected Cash balance</td>
                <td class="align-right">{{ cashDrawerReport?.expected_in_drawer || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Ending Cash</td>
                <td class="align-right">{{ cashDrawerReport?.actual_in_drawer || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Short / Over</td>
                <td class="align-right">{{ cashDrawerReport?.difference || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td>Cash Drop</td>
                <td class="align-right">{{ cashDrawerReport?.cash_drop || 0 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="!pt-6 font-extrabold">Type({{cashDrawerReport?.total_denom_qty}})</td>
                <td class="!pt-6 font-extrabold align-right">Total</td>
              </tr>
              <tr>
                <td class="sub-heading">Hundreds({{cashDrawerReport?.dollar_100}})</td>
                <td class="align-right">{{ cashDrawerReport?.dollar_100 * 100 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Fifties({{cashDrawerReport?.dollar_50}})</td>
                <td class="align-right">{{ cashDrawerReport?.dollar_50 * 50 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Twenties({{cashDrawerReport?.dollar_20}})</td>
                <td class="align-right">{{ cashDrawerReport?.dollar_20 * 20 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Tens({{cashDrawerReport?.dollar_10}})</td>
                <td class="align-right">{{ cashDrawerReport?.dollar_10 * 10 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Fives({{cashDrawerReport?.dollar_5}})</td>
                <td class="align-right">{{ cashDrawerReport?.dollar_5 * 5 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Ones({{cashDrawerReport?.dollar_1}})</td>
                <td class="align-right">{{ cashDrawerReport?.dollar_1 * 1 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Half({{cashDrawerReport?.cent_50}})</td>
                <td class="align-right">{{ cashDrawerReport?.cent_50 * 0.5 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Quarters({{cashDrawerReport?.cent_25}})</td>
                <td class="align-right">{{ cashDrawerReport?.cent_25 * 0.25 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Dimes({{cashDrawerReport?.cent_10}})</td>
                <td class="align-right">{{ cashDrawerReport?.cent_10 * 0.10 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Nickels({{cashDrawerReport?.cent_5}})</td>
                <td class="align-right">{{ cashDrawerReport?.cent_5 * 0.05 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="sub-heading">Pennies({{cashDrawerReport?.cent_1}})</td>
                <td class="align-right">{{ cashDrawerReport?.cent_1 * 0.01 | currency:'USD'}}</td>
              </tr>
              <tr>
                <td class="font-extrabold">Total</td>
                <td class="align-right">{{ cashDrawerReport?.expected_in_drawer || 0 | currency:'USD'}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>