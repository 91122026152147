import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrderService } from 'src/app/_services/order.service';
import { AddGiftCardValueComponent } from '../add-gift-card-value/add-gift-card-value.component';
@Component({
  selector: 'app-gift-balance-inquiry',
  templateUrl: './gift-balance-inquiry.component.html',
  styleUrls: ['./gift-balance-inquiry.component.scss']
})
export class GiftBalanceInquiryComponent implements OnInit {
  cardNumber: string;
  balanceData: any;
  isLoading = true;
  currentStore: string;
  constructor(
    private dialogRef: MatDialogRef<GiftBalanceInquiryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private giftCardService: OrderService,
    public dialog: MatDialog
  ) {
    this.cardNumber = data.data;
  }
  ngOnInit(): void {
    this.fetchBalanceData();
  }
  fetchBalanceData(): void {
    this.currentStore = localStorage.getItem('currentStore');
    this.giftCardService.getPhysicalGiftcard(this.cardNumber, this.currentStore).subscribe({
      next: (response) => {
        this.balanceData = response;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
      }
    });
  }
  onOk(): void {
    this.dialogRef.close(true);
  }
  onAddValue() {
    const isMobile = window.innerWidth <= 599;
    let dialogref = this.dialog.open(AddGiftCardValueComponent, {
      width: isMobile ? '100vw' : '435px',
      data: { from: 'balance-check-popup', id: this.data.orderId, store:this.data.store,  cardDetails: this.balanceData},
    });
    dialogref.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }
}