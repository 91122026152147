<div class="p-8 bg-white rounded-lg shadow-lg w-full max-w-[98%] sm:max-w-[95%] md:max-w-[850px] lg:max-w-[1100px]">
    <h2 class="text-2xl font-medium text-gray-800 mb-6">Add Value</h2>
    <div class="mb-10 flex items-center">
        <span class="text-gray-700 text-lg font-medium mr-3">Amount:</span>
        <span class="text-gray-700 text-xl font-medium mr-2">$</span>
        <input type="text" id="amount" [(ngModel)]="formattedValue" (input)="onInput($event)"
            class="w-full px-4 py-4 border border-gray-300 rounded-lg text-gray-700 text-right text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
    </div>
    <div class="flex gap-4">
        <button
            class="w-1/2 px-6 py-4 text-lg font-medium text-gray-700 bg-gray-200 border border-gray-300 rounded-lg hover:bg-gray-300"
            (click)="onBack()">
            Cancel
        </button>
        <button
            class="w-1/2 px-6 py-4 text-lg font-medium text-white bg-blue-600 border border-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            (click)="onNext()" [disabled]="!isAmountValid">
            Next
        </button>
    </div>
</div>