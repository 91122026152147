<div class="container !p-8">
    <button (click)="cancel()"
        class="border border-blue-500 text-blue-500 font-medium text-lg rounded-lg px-4 py-2 hover:bg-blue-500 hover:text-white transition duration-300">
        Go Back
    </button>
    <div class="text-center bg-white rounded-lg shadow-none w-full max-w-lg mt-[14px]">
        <!-- Gift Card Payment Info -->
        <h2 class="text-2xl font-medium mb-4">Gift Card Payment:</h2>
        <p class="text-lg text-gray-700 mb-6">
            <span class="font-medium text-gray-900">${{this.crdBalance}}</span> has been deducted from your gift card
            balance.
        </p>
        <!-- Remaining Amount Info -->
        <h3 class="text-2xl font-medium mb-4">Remaining Amount:</h3>
        <p class="text-lg text-gray-700 mb-6">
            You still need to pay <span class="font-medium text-gray-900">${{this.remainingAmount}}</span> to complete
            your
            order.
        </p>
        <!-- Buttons Section -->
        <div class="grid grid-cols-7 gap-4 mt-8 items-center">
            <!-- Cash Button -->
            <div class="col-span-3">
                <button
                    class="w-full py-4 bg-blue-600 hover:bg-blue-600 text-white text-2xl font-medium rounded-lg shadow-md"
                    (click)="partialChekout('cash')">
                    Cash
                </button>
            </div>
            <!-- OR Divider -->
            <div class="col-span-1 text-gray-600 text-center font-semibold">
                OR
            </div>
            <!-- Card Button -->
            <div class="col-span-3">
                <button
                    class="w-full py-4 bg-blue-600 hover:bg-blue-600 text-white text-2xl font-medium rounded-lg shadow-md"
                    (click)="partialChekout('card')">
                    Card
                </button>
            </div>
        </div>
    </div>
</div>