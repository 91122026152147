<table class="table w-full">
  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.order_counter">
    <td>
      <mat-icon >tag</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.order_counter}}</td>
  </tr>
  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.phone_number">
    <td>
      <mat-icon>phone</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.phone_number | phone}}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.order_name">
    <td>
      <mat-icon >badge</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.order_name}}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.special_request">
    <td>
      <mat-icon >edit</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.special_request}}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.occasion === 'DELIVERY' && orderWithPayment?.bot_order.delivery_address">
    <td>
      <mat-icon >delivery_dining</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.delivery_address}}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.dine_in_table_number">
    <td>
      <mat-icon >table_restaurant</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.dine_in_table_number}}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.submitted_at">
    <td>
      <mat-icon >schedule</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.submitted_at | date:'hh:mm a EEE MMM dd'}}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.occasion_schedule">
    <td>
      <mat-icon >timer</mat-icon>
    </td>
    <!-- <td>{{orderWithPayment?.bot_order.occasion_schedule | date:'hh:mm a EEE MMM dd' }}</td> -->
    <td>{{orderWithPayment.bot_order.est_time ? (orderWithPayment.bot_order.est_time_and_date | date:'hh:mm a EEE MMM dd') :
      (orderWithPayment.bot_order.occasion_schedule | date:'hh:mm a EEE MMM dd') }}</td>
  </tr>

  <tr class="even:bg-gray-50 odd:bg-white dark-bg-color text-clr" *ngIf="orderWithPayment?.bot_order.parking_spot">
    <td>
      <mat-icon >local_parking</mat-icon>
    </td>
    <td>{{orderWithPayment?.bot_order.parking_spot}}</td>
  </tr>
</table>

