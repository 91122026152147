<div class="container !p-5" *ngIf="!openFromSplit && !openFromgift">
  <button (click)="onNoClick()"
    class="border border-blue-500 text-blue-500 font-medium text-lg rounded-lg px-4 py-2 hover:bg-blue-500 hover:text-white transition duration-300">
    Go Back
  </button>
  <div class="grid grid-cols-3 gap-2 items-center justify-items-center p-3" *ngIf="store.enable_consumer_choice">
    <div class="cash-sec p-3 xs:!p-0 drop-shadow-md flex flex-row bg-gray-100 items-center justify-center rounded-md">
      <div class="text-center">
        <span class="text-xl">Cash Due</span><br>
        <span class="text-3xl">{{orderWithPayment?.payment.cash_due_total | currency}}</span>
      </div>
    </div>

    <div class="p-2 border border-gray-300 rounded-full">OR</div>

    <div class="cash-sec p-3 xs:!p-0 drop-shadow-md flex flex-row bg-gray-100 items-center justify-center rounded-md">
      <div class="text-center">
        <span class="text-xl">Card Due</span><br>
        <span class="text-3xl">{{orderWithPayment?.payment.due_total | currency}}</span>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-3 gap-2 items-center justify-items-center p-5" *ngIf="!store.enable_consumer_choice">
    <div class="cash-sec p-3 drop-shadow-md flex flex-row bg-gray-100 items-center justify-center rounded-md">
      <div class="text-center">
        <span class="text-2xl">Due Total</span><br>
        <span class="text-3xl">{{orderWithPayment?.payment.due_total | currency}}</span>
      </div>
    </div>
  </div>

  <!-- <div class="grid grid-cols-6 xs:!grid-cols-3 gap-2"> -->
  <div class="grid grid-cols-6 xs:!grid-cols-4 gap-2">
    <div class="col-span-6 my-2">
      <input type="text" class="w-full rounded-md bg-gray-100 drop-shadow-md p-2 text-3xl text-right"
        [ngModel]="result | currency:'USD':''" readonly>
    </div>
    <div class="col-span-3 grid grid-cols-3 gap-2">
      <input matRipple type="button" name="" [value]="i+1"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="pressNum(i+1)"
        *ngFor="let num of [].constructor(10) | slice:1; let i = index" />
      <input matRipple type="button" name="" value="0"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="pressNum('0')" />
      <input matRipple type="button" name="" value="00"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="pressNum('00')" />
      <input matRipple type="button" name="" value="C"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="allClear()" />
    </div>
    <div class="grid grid-cols-1 grid-rows-4 gap-2 items-start">
      <input type="button" name="" [value]="val1"
        class="price-default border lab-border-blue lab-text-blue text-xl drop-shadow-md rounded-md bg-white hover:bg-blue-100 hover:shadow-blue-100"
        (click)="pressing(val1)" [ngClass]="dollarInput1 == val1 ? 'input-hglght': ''">
      <input type="button" name="" [value]="val2"
        class="border lab-border-blue lab-text-blue text-xl drop-shadow-md rounded-md bg-white hover:bg-blue-100 hover:shadow-blue-100"
        (click)="pressing2(val2)" [ngClass]="dollarInput2 == val2 ? 'input-hglght': ''">
      <input type="button" name="" [value]="val3"
        class="border lab-border-blue lab-text-blue text-xl drop-shadow-md rounded-md bg-white hover:bg-blue-100 hover:shadow-blue-100"
        (click)="pressing3(val3)" [ngClass]="dollarInput3 == val3 ? 'input-hglght': ''">
      <button class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100 h-full" (click)="clear()">
        <span class="flex justify-center">
          <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
              fill="#636363" />
          </svg>
        </span>
      </button>
    </div>

    <div class="grid grid-cols-1 grid-rows-4 gap-2 items-start xs:!hidden">
      <button class="border lab-border-blue text-3xl drop-shadow-md rounded-md lab-bg-blue text-white h-full
        hover:bg-blue-500 hover:shadow-blue-100" (click)="openCardDialog()">
        Card
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-lime-600 bg-lime-600 hover:bg-lime-400" [disabled]="number(result) < 0" (click)="openCashDialog()">
        Cash
      </button>
      <button class="border text-2xl drop-shadow-md rounded-md text-white h-full w-full
                border-gray-600 bg-gray-600 hover:bg-gray-400" (click)="openManualCardDialog()">
        Manual Card
      </button>
      <button class="border text-2xl drop-shadow-md rounded-md text-white h-full w-full
                border-[#008b8b] bg-[#008b8b] hover:bg-[#006b6b]" (click)="openGiftCardDialog()">
        Gift Cards
      </button>
    </div>

    <div class="grid grid-cols-1 grid-rows-4 gap-2 items-start xs:!hidden">
      <button class="border lab-border-blue text-3xl drop-shadow-md rounded-md lab-bg-blue text-white h-full
        hover:bg-blue-500 hover:shadow-blue-100" (click)="openSplitPayment()">
        Split
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-purple-600 bg-purple-600 hover:bg-purple-400" (click)="openOtherPaymentDialog('GIFT')">
        Gift
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-amber-900 bg-amber-900 hover:bg-amber-600" (click)="submitKeyedOrder()">
        Keyed
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-yellow-500 bg-yellow-500 hover:bg-yellow-400" (click)="openOtherPaymentDialog('OTHER')">
        Other
      </button>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2">
    <div class="grid grid-cols-1 gap-2 items-start hidden xs:!block">
      <button class="border lab-border-blue text-base h-10 mt-2 mb-1 drop-shadow-md rounded-md lab-bg-blue w-full text-white
      hover:bg-blue-500 hover:shadow-blue-100" (click)="openCardDialog()">
        Card
      </button>
      <button class="border text-base h-10 mt-1 mb-1 drop-shadow-md rounded-md text-white w-full
        border-lime-600 bg-lime-600 hover:bg-lime-400" [disabled]="number(result) < 0" (click)="openCashDialog()">
        Cash
      </button>
      <button class="border text-base h-10 mt-1 mb-1 drop-shadow-md  text-base h-10 mt-1 mb-1 rounded-md text-white w-full
              border-gray-600 bg-gray-600 hover:bg-gray-400" (click)="openManualCardDialog()">
        Manual Card
      </button>
      <button class="border text-base h-10 mt-1 mb-1 drop-shadow-md  text-base h-10 mt-1 mb-1 rounded-md text-white w-full
              border-gray-600 bg-gray-600 hover:bg-gray-400" (click)="openGiftCardDialog()">
        Gift Cards
      </button>
    </div>

    <div class="grid grid-cols-1 gap-2 items-start hidden xs:!block">
      <button class="border lab-border-blue text-base h-10 mt-2 mb-1 drop-shadow-md w-full rounded-md lab-bg-blue text-white
      hover:bg-blue-500 hover:shadow-blue-100" (click)="openSplitPayment()">
        Split
      </button>
      <button class="border text-base h-10 mt-1 mb-1 drop-shadow-md rounded-md text-white w-full
        border-purple-600 bg-purple-600 hover:bg-purple-400" (click)="openOtherPaymentDialog('GIFT')">
        Gift
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white text-base h-10 mt-1 mb-1 w-full
        border-amber-900 bg-amber-900 hover:bg-amber-600" (click)="submitKeyedOrder()">
        Keyed
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white text-base h-10 mt-1 mb-1 w-full
        border-yellow-500 bg-yellow-500 hover:bg-yellow-400" (click)="openOtherPaymentDialog('OTHER')">
        Other
      </button>
    </div>
  </div>
</div>


<!-- SPLIT AND GIFT CASH ORDER -->
<div class="container !p-5" *ngIf="openFromSplit || openFromgift">
  <button (click)="onNoClick()"
    class="border border-blue-500 text-blue-500 font-medium text-lg rounded-lg px-4 py-2 hover:bg-blue-500 hover:text-white transition duration-300">
    Go Back
  </button>
  <div class="grid grid-cols-3 gap-2 items-center justify-items-center p-3" *ngIf="store.enable_consumer_choice">
    <div class="cash-sec p-3 xs:!p-0 drop-shadow-md flex flex-row bg-gray-100 items-center justify-center rounded-md">
      <div class="text-center">
        <span class="text-xl">Order Value:</span><br>
        <span class="text-3xl">{{splitAmount | currency}}</span>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-6 xs:!grid-cols-4 gap-2">
    <div class="col-span-6 my-2">
      <input type="text" class="w-full rounded-md bg-gray-100 drop-shadow-md p-2 text-3xl text-right"
        [ngModel]="result | currency:'USD':''" readonly>
    </div>
    <div class="col-span-3 grid grid-cols-3 gap-2">
      <input matRipple type="button" name="" [value]="i+1"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="pressNum(i+1)"
        *ngFor="let num of [].constructor(10) | slice:1; let i = index" />
      <input matRipple type="button" name="" value="0"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="pressNum('0')" />
      <input matRipple type="button" name="" value="00"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="pressNum('00')" />
      <input matRipple type="button" name="" value="C"
        class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100" (click)="allClear()" />
    </div>
    <div class="grid grid-cols-1 grid-rows-4 gap-2 items-start">
      <input type="button" name="" [value]="val1"
        class="price-default border lab-border-blue lab-text-blue text-xl drop-shadow-md rounded-md bg-white hover:bg-blue-100 hover:shadow-blue-100"
        (click)="pressing(val1)" [ngClass]="dollarInput1 == val1 ? 'input-hglght': ''">
      <input type="button" name="" [value]="val2"
        class="border lab-border-blue lab-text-blue text-xl drop-shadow-md rounded-md bg-white hover:bg-blue-100 hover:shadow-blue-100"
        (click)="pressing2(val2)" [ngClass]="dollarInput2 == val2 ? 'input-hglght': ''">
      <input type="button" name="" [value]="val3"
        class="border lab-border-blue lab-text-blue text-xl drop-shadow-md rounded-md bg-white hover:bg-blue-100 hover:shadow-blue-100"
        (click)="pressing3(val3)" [ngClass]="dollarInput3 == val3 ? 'input-hglght': ''">
      <button class="border text-3xl drop-shadow-md rounded-md hover:bg-gray-100 h-full" (click)="clear()">
        <span class="flex justify-center">
          <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
              fill="#636363" />
          </svg>
        </span>
      </button>
    </div>

    <div class="grid grid-cols-1 grid-rows-4 gap-2 items-start xs:!hidden">
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-lime-600 bg-lime-600 hover:bg-lime-400" [disabled]="number(result) < 0" (click)="openCashDialog()"
        *ngIf="openFromgift">
        Cash
      </button>
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-lime-600 bg-lime-600 hover:bg-lime-400" [disabled]="number(result) < 0" (click)="splitCashPayment()"
        *ngIf="paymentType == 'CASH' && openFromSplit">
        Cash
      </button>
    </div>
    <div class="grid grid-cols-1 grid-rows-4 gap-2 items-start xs:!hidden">
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-yellow-500 bg-yellow-500 hover:bg-yellow-400" [disabled]="number(result) < 0"
        (click)="splitCashPayment()" *ngIf="paymentType == 'OTHER' && openFromSplit">
        Other
      </button>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2">
    <div class="grid grid-cols-1 gap-2 items-start hidden xs:!block">
      <button class="border text-3xl drop-shadow-md rounded-md text-white h-full w-full
          border-lime-600 bg-lime-600 hover:bg-lime-400" [disabled]="number(result) < 0" (click)="openCashDialog()"
        *ngIf="openFromgift">
        Cash
      </button>
      <button class="border text-base h-10 mt-1 mb-1 drop-shadow-md rounded-md text-white w-full
        border-lime-600 bg-lime-600 hover:bg-lime-400" [disabled]="number(result) < 0" (click)="splitCashPayment()"
        *ngIf="paymentType == 'CASH' && openFromSplit">
        Cash
      </button>
    </div>

    <div class="grid grid-cols-1 gap-2 items-start hidden xs:!block">
      <button class="border text-3xl drop-shadow-md rounded-md text-white text-base h-10 mt-1 mb-1 w-full
        border-yellow-500 bg-yellow-500 hover:bg-yellow-400" [disabled]="number(result) < 0"
        (click)="splitCashPayment()" *ngIf="paymentType == 'OTHER' && openFromSplit">
        Other
      </button>
    </div>
  </div>
</div>