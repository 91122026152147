// import { Component, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// @Component({
//   selector: 'app-worldnet-dialog',
//   templateUrl: './worldnet-dialog.component.html',
//   styleUrls: ['./worldnet-dialog.component.scss']
// })
// export class WorldnetDialogComponent implements AfterViewInit {
//   @ViewChild('iframeRef') iframeRef!: ElementRef;
//   private loadCounter = 0;

//   constructor(
//     @Inject(MAT_DIALOG_DATA) public data: { formHtml: string },
//     private dialogRef: MatDialogRef<WorldnetDialogComponent>
//   ) {}

//   ngAfterViewInit(): void {
//     const iframe: HTMLIFrameElement = this.iframeRef.nativeElement;
//     const iframeDoc = iframe.contentWindow!.document;

//     iframeDoc.open();
//     iframeDoc.write(this.data.formHtml);
//     iframeDoc.close();

//     const form = iframeDoc.getElementsByTagName('form')[0];
//     form.submit();

//     // Listen for iframe load events
//     iframe.onload = () => {
//       this.loadCounter += 1;
//       if (this.loadCounter === 2) {
//         this.dialogRef.close(true); // Close the dialog on the second load
//       }
//     };
//   }
// }
import { Component, OnInit, OnDestroy, Inject, Input, HostBinding, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

declare const Payroc: any; // Declare Payroc for Hosted Fields library.

@Component({
  selector: 'app-worldnet-dialog',
  templateUrl: './worldnet-dialog.component.html',
  styleUrls: ['./worldnet-dialog.component.scss']
})
export class WorldnetDialogComponent implements OnInit, OnDestroy {
  private hostedFieldsInstance: any;

  @Input() data!: { sessionToken: string, phone_number: string };
  phoneNumber: string;
  @Output() paymentDetailsEmitter: EventEmitter<any> = new EventEmitter();
  optin: boolean = true;
  constructor(
    private _snackBar: MatSnackBar
    // @Inject(MAT_DIALOG_DATA) public data: { sessionToken: string },
    // private dialogRef: MatDialogRef<WorldnetDialogComponent>
  ) { }

  ngOnInit(): void {
    console.log('Session Token:', this.data);
    this.phoneNumber = this.data.phone_number;
    this.initializeHostedFields(this.data.sessionToken);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      console.log('Updated Data in Child Component:', this.data);
      const formattedPhoneNumber = `+1${this.data.phone_number.replace(/\D/g, '')}`;
      if (formattedPhoneNumber.length === 12 && this.phoneNumber != formattedPhoneNumber) {
        this.phoneNumber = formattedPhoneNumber;
      } else if (this.data.phone_number = '') {
        this.phoneNumber = '';
      }
      
    }
  }

  private initializeHostedFields(sessionToken: string): void {
    if (!sessionToken) {
      console.error("Session token is required to initialize Hosted Fields.");
      return;
    }

    this.hostedFieldsInstance = new Payroc.hostedFields({
      sessionToken: sessionToken,
      mode: "payment",
      fields: {
        card: {
          cardholderName: {
            target: ".card-holder-name",
            placeholder: "Cardholder Name",
          },
          cardNumber: {
            target: ".card-number",
            placeholder: "1234 5678 1234 1211",
          },
          cvv: {
            target: ".card-cvv",
            wrapperTarget: ".card-cvv-wrapper",
            placeholder: "CVV",
          },
          expiryDate: {
            target: ".card-expiry",
            placeholder: "MM/YY",
          },
          submit: {
            target: ".submit-button",
            value: "Charge Card",
          },
        },
      },
      styles: {
        disableDefaultStyles: true,
        css: {
          body: {
            margin: "0",
          },
          form: {
            display: "flex",
            "align-items": "center",
          },
          input: {
            "line-height": "2",
            "box-sizing": "border-box",
            border: "1px #000000 solid",
            width: "100%",
            height: "55px",
            padding: "8px",
            "background-color": "#FFF",
            color: "#000000",
            "text-align": "left",
            "font-size": "16px",
            "margin-bottom": '5px'
          },
          label: {
            padding: "8px 0",
            "font-family": "Arial",
            "font-size": "0.8rem",
            display: "inline-block",
          },
          ":focus": {
            outline: "none",
          },
          "::placeholder": {
            color: "rgb(158, 158, 158)",
          },
          "input[type='text']": {
            "min-height": "45px",
          },
          "::before": {
            content: "",
            width: "1rem",
            height: "1rem",
            "flex-basis": "1",
            "border-radius": "50%",
            transform: "scale(0)",
            "transform-origin": "center center",
            transition: "60ms transform ease-in-out",
            "box-shadow": "inset 1rem 1rem rgb(23, 134, 97)",
          },
          ":checked::before": {
            transform: "scale(1)",
            "transform-origin": "center center",
            width: "23px",
            color: "red",
            outline: "1px black solid",
            "line-height": "2",
          },
          button: {
            "background-color": "rgb(14, 130, 253)",
            color: "#FFF",
            border: "1px rgb(14, 130, 253) solid",
            "border-radius": "4px",
            width: "100%",
            "text-align": "center",
            "min-height": "45px",
            padding: "8px",
            "font-size": "20px",
          },
          div: {
            margin: "0",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "margin-left": "8px",
          },
          "input.invalid": {
            color: "#d72d2d",
            "border-color": "#d72d2d",
          },
          ".loading-svg": {
            display: "none",
            color: "rgb(255, 255, 255)",
          },
          ".button-text": {
            display: "inline-block",
          },
          ".loading .loading-svg": {
            display: "inline-block",
          },
          ".loading .button-text": {
            display: "none",
          },
          ".spin": {
            animation: "spin 1s linear infinite",
          },
          "@keyframes spin": {
            to: {
              transform: "rotate(1turn)",
            },
          },
          "button:hover": {
            cursor: "pointer",
          },
        },
      },
      onPreSubmit: async () => {
        // Custom validation for zip-code
        const zipCode = (document.getElementById("zip-code") as HTMLInputElement)?.value || "";

        // if (!this.phoneNumber) {
        //   this._snackBar.open('Please enter the phone number', 'OK', { duration: 3000, verticalPosition: 'top' });
        //   return false;
        // }

        if (zipCode.trim().length === 0) {
          this._snackBar.open('Please enter the zip code', 'OK', { duration: 3000, verticalPosition: 'top' });
          return false;
        }

        if (zipCode.trim().length < 5) {
          this._snackBar.open('Zip code must be at least 5 characters long.', 'OK', { duration: 3000, verticalPosition: 'top' });
          return false;
        }
        console.log("Custom field validation passed!");
        return true; // Allow form submission
      },
    });

    // Handle submission success
    this.hostedFieldsInstance.on("submissionSuccess", ({ token, expiresAt }) => {
      const zipcode = (document.getElementById("zip-code") as HTMLInputElement)?.value || "";

      const paymentDetails = {
        token,
        expiresAt,
        zipcode: zipcode,
      };

      console.log("Payment Details:", paymentDetails);
      this.paymentDetailsEmitter.emit(paymentDetails);
      // Send payment details to your backend
    });

    // Handle errors
    this.hostedFieldsInstance.on("error", ({ type, field, message }) => {
      console.error(`Error in field "${field}": ${message}`);
    });

    this.hostedFieldsInstance.initialize();
  }


  ngOnDestroy(): void {
    if (this.hostedFieldsInstance) {
      if (typeof this.hostedFieldsInstance.destroy === "function") {
        this.hostedFieldsInstance.destroy();
      } else {
        console.warn("Destroy method not available for hostedFieldsInstance.");
      }
    }
  }
}
