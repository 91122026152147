import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {PermissionService} from 'src/app/_services/permission.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss']
})
export class PermissionDialogComponent implements OnInit {

  constructor(private permissionService: PermissionService,
    private formBuilder:UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PermissionDialogComponent>,) { }

  form: UntypedFormGroup

  ngOnInit() {
    this.permissionService.getUserPermissionById(this.data.store.id,
      this.data.user.id).subscribe(p => {
      console.log('permissionService',p);
      let forForm = {
        'store_id': this.data.store.id,
        'user_id': this.data.user.id,
        'permissions': this.formBuilder.group({
          'ACCESS': this.formBuilder.group({
            'HOME': [this.getPermissionValue(p,'ACCESS', 'HOME')],
            'ORDERS': [this.getPermissionValue(p,'ACCESS', 'ORDERS')],
            'MENU': [this.getPermissionValue(p,'ACCESS', 'MENU')],
            'MESSAGES': [this.getPermissionValue(p,'ACCESS', 'MESSAGES')],
            'GIFT_CARDS': [this.getPermissionValue(p,'ACCESS', 'GIFT_CARDS')],
            'CAMPAIGNS': [this.getPermissionValue(p,'ACCESS', 'CAMPAIGNS')],
            'ATTENDANT': [this.getPermissionValue(p,'ACCESS', 'ATTENDANT')],
            'SKILLS': [this.getPermissionValue(p,'ACCESS', 'SKILLS')],
            'ACCOUNT': [this.getPermissionValue(p,'ACCESS', 'ACCOUNT')],
            'USERS': [this.getPermissionValue(p,'ACCESS', 'USERS')],
            'INSIGHTS': [this.getPermissionValue(p,'ACCESS', 'INSIGHTS')],
            'OFFERS': [this.getPermissionValue(p,'ACCESS', 'OFFERS')],
            'DIGITAL_DISPLAY': [this.getPermissionValue(p,'ACCESS', 'DIGITAL_DISPLAY')],
            'FEEDBACK': [this.getPermissionValue(p,'ACCESS', 'FEEDBACK')],
            'LOYALTY': [this.getPermissionValue(p,'ACCESS', 'LOYALTY')],
            'FLOOR_PLAN': [this.getPermissionValue(p,'ACCESS', 'FLOOR_PLAN')],
            'REFERRAL': [this.getPermissionValue(p,'ACCESS', 'REFERRAL')],
            'MANAGE_USERS': [this.getPermissionValue(p,'ACCESS', 'MANAGE_USERS')],
            'TIME_CLOCK': [this.getPermissionValue(p,'ACCESS', 'TIME_CLOCK')],
            'POS_SETTINGS': [this.getPermissionValue(p,'ACCESS', 'POS_SETTINGS')],
            'SETTLEMENTS': [this.getPermissionValue(p,'ACCESS', 'SETTLEMENTS')],
            'SUPPORT': [this.getPermissionValue(p,'ACCESS', 'SUPPORT')],
          }),
          'ORDERS': this.formBuilder.group({
            'POS': [this.getPermissionValue(p,'ORDERS', 'POS')],
            'CLOSED_ORDERS': [this.getPermissionValue(p,'ORDERS', 'CLOSED_ORDERS')],
            'REPORTING': [this.getPermissionValue(p,'ORDERS', 'REPORTING')],
            'STATEMENTS': [this.getPermissionValue(p,'ORDERS', 'STATEMENTS')],
            'MASTER_PIN': [this.getPermissionValue(p,'ORDERS', 'MASTER_PIN')],
            'INSTANT_DISCOUNT': [this.getPermissionValue(p,'ORDERS', 'INSTANT_DISCOUNT')],
          }),
          'POS_FUNCTIONS': this.formBuilder.group({
            'OPEN_DRAWER': [this.getPermissionValue(p,'POS_FUNCTIONS', 'OPEN_DRAWER')],
            'VOID': [this.getPermissionValue(p,'POS_FUNCTIONS', 'VOID')],
            'REFUND': [this.getPermissionValue(p,'POS_FUNCTIONS', 'REFUND')],
            'CANCEL':[this.getPermissionValue(p,'POS_FUNCTIONS', 'CANCEL')],
            'ADJUST':[this.getPermissionValue(p,'POS_FUNCTIONS', 'ADJUST')],
            'MODIFY':[this.getPermissionValue(p,'POS_FUNCTIONS', 'MODIFY')],
            'MANAGE_DRAWER': [this.getPermissionValue(p,'POS_FUNCTIONS', 'MANAGE_DRAWER')],
            'UNDO': [this.getPermissionValue(p,'POS_FUNCTIONS', 'UNDO')],
          }),
          'ALERT': this.formBuilder.group({
            'ORDER_SMS': [this.getPermissionValue(p,'ALERT', 'ORDER_SMS')],
            'ORDER_EMAIL': [this.getPermissionValue(p,'ALERT', 'ORDER_EMAIL')],
            'MESSAGE_SMS': [this.getPermissionValue(p,'ALERT', 'MESSAGE_SMS')],
            'MESSAGE_EMAIL': [this.getPermissionValue(p,'ALERT', 'MESSAGE_EMAIL')],
            'PRINTER_SMS': [this.getPermissionValue(p,'ALERT', 'PRINTER_SMS')],
            'PRINTER_EMAIL': [this.getPermissionValue(p,'ALERT', 'PRINTER_EMAIL')],
            'STATUS_SMS': [this.getPermissionValue(p,'ALERT', 'STATUS_SMS')],
            'STATUS_EMAIL': [this.getPermissionValue(p,'ALERT', 'STATUS_EMAIL')],
            'OPEN_ORDER': [this.getPermissionValue(p,'ALERT', 'OPEN_ORDER')],
            'FEEDBACK_SMS': [this.getPermissionValue(p,'ALERT', 'FEEDBACK_SMS')],
            'FEEDBACK_EMAIL': [this.getPermissionValue(p,'ALERT', 'FEEDBACK_EMAIL')]
          }),
          'REPORTING': this.formBuilder.group({
            'SEARCH': [this.getPermissionValue(p, 'REPORTING', 'SEARCH')],
            'PERIOD_REPORT_MASTER': [this.getPermissionValue(p,'REPORTING', 'PERIOD_REPORT_MASTER')],
            'VIEW_PERIOD_REPORT_MASTER': [this.getPermissionValue(p,'REPORTING', 'VIEW_PERIOD_REPORT_MASTER')],
            'VIEW_CATEGORY_REPORT_MASTER': [this.getPermissionValue(p,'REPORTING', 'VIEW_CATEGORY_REPORT_MASTER')],
            'VIEW_LABOR_REPORT_MASTER': [this.getPermissionValue(p,'REPORTING', 'VIEW_LABOR_REPORT_MASTER')],
            'VIEW_FOOD_COST_REPORT': [this.getPermissionValue(p,'REPORTING', 'VIEW_FOOD_COST_REPORT')],
            'VIEW_PROFITABILITY_REPORT': [this.getPermissionValue(p,'REPORTING', 'VIEW_PROFITABILITY_REPORT')],
            'VIEW_CASH_DRAWER': [this.getPermissionValue(p,'REPORTING', 'VIEW_CASH_DRAWER')],
          }),
          'CLOCK': this.formBuilder.group({
            'EDIT': [this.getPermissionValue(p, 'CLOCK', 'EDIT')],
            'DISABLE_AUTO_CLOCKOUT': [this.getPermissionValue(p, 'CLOCK', 'DISABLE_AUTO_CLOCKOUT')]
          })
        })
      }
      this.form = this.formBuilder.group(forForm);
      console.log(forForm);
      console.log(this.form);
    })
  }

  getPermissionValue(p, group, permission){
    return group in p && p[group] && p[group].includes(permission)
  }

  onSubmit(){
    console.log(this.form.value);
    this.permissionService.postUserPermission(this.form.value).subscribe(data => {
      this.permissionService.getUserPermission(this.data.store.id);
      this.dialogRef.close();
    })
  }

}
