<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="!isLoading">
  <mat-accordion cdkDropList [cdkDropListData]="selectedMods" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let modGroup of selectedMods">
      <mat-expansion-panel cdkDrag disabled class="not-open">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span cdkDragHandle class="cursor-move relative top-[2px] right-[2px]">
              <mat-icon>drag_indicator</mat-icon>
            </span>
          <span class="w-full"> {{modGroup.name}} </span>
          <span><mat-icon *ngIf="removable" (click)="remove(modGroup)" class="top-[3px] relative">cancel</mat-icon></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-container>
    <mat-form-field class="example-full-width">
      <input type="text"
             placeholder="Modifier Groups"
             matInput
             [formControl]="modCtrl"
             [matAutocomplete]="auto"
             (input)="add($event)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let mod of filteredMods | async" [value]="mod">
          {{mod.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-accordion>
</div>