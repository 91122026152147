<div class="p-[20px] flex flex-col gap-5">
    <div class="p-4 border border-gray-300 rounded-lg">
        <form (ngSubmit)="onSubmit()" #fileUploadForm="ngForm" class="flex items-center space-x-4">
            <div>
                <label for="fileInput" class="sr-only">Choose File:</label>
                <input type="file" id="fileInput" (change)="onFileChange($event)" required ngModel name="fileInput"
                    #fileInput
                    class="text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer focus:outline-none" />
            </div>
            <button type="submit" [disabled]="!selectedFile"
                class="px-4 py-2 text-white rounded-lg bg-[#1e88e5] focus:ring-4 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed">
                Upload
            </button>
        </form>
    </div>
    <div fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row wrap"
            fxLayoutAlign="start center" fxLayout="row wrap" fxFlex="100" class="message-table gift-mobile">
            <form class="order-message-table-filters flex-wrap">
                <mat-form-field appearance="fill" class="search">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label>Search Orders</mat-label>
                    <input [(ngModel)]="search_str" name="search_str" matInput placeholder="Search orders" #input
                        (input)="searchOrders($event)">
                </mat-form-field>
                <mat-form-field appearance="fill" class="start">
                    <mat-label>Start Date</mat-label>
                    <input [(ngModel)]="from_date" name="from_date" matInput [matDatepicker]="startPicker"
                        placeholder="Choose a start date" (dateInput)="searchOrders($event)">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #startPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill" class="end">
                    <mat-label>End Date</mat-label>
                    <input [(ngModel)]="to_date" name="to_date" matInput [matDatepicker]="endPicker"
                        placeholder="Choose a end date" (dateInput)="searchOrders($event)" [min]="startDate">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
                <button mat-flat-button class="apply-btn-colr clear action-btn-colr create" (click)="reset()"
                    color="primary">CLEAR</button>
                <div class="clear"></div>
            </form>
        </div>
        <div class="cardholders-table-div  table-div full-y-width  xs:!overflow-x-scroll  xs:m-auto">
            <div class="cardholders-table-mobile-scroll">
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="id"
                    matSortDirection="desc" matSortDisableClear>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                        <td mat-cell *matCellDef="let element">{{element.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="phone_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
                        <td mat-cell *matCellDef="let element">{{element.phone_number}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element">{{element.status}}</td>
                    </ng-container>
                    <ng-container matColumnDef="card_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Card Number </th>
                        <td mat-cell *matCellDef="let element">{{element.card_number}}</td>
                    </ng-container>
                    <ng-container matColumnDef="card_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Card Type </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.card_type == 'physical'">Physical</span>
                            <span *ngIf="element.card_type == 'digital'">Digital</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Current Balance </th>
                        <td mat-cell *matCellDef="let element">{{element.balance | currency:'USD'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                        <td mat-cell *matCellDef="let element">{{element.created_at | date: 'MM/dd/yyyy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="expand row"
                                (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"
                                (click)="onRowClicked(element)">
                                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                            <div class="example-element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div style="width: 100%;max-height: 200px;overflow-y: auto;">
                                    <p>Redemption History</p>
                                    <table mat-table [dataSource]="innerDatasource" style="background-color: #ededed;">
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.id}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="redeemed_amount">
                                            console.log(row);
                                            <th mat-header-cell *matHeaderCellDef> Redeemed Amount </th>
                                            <td mat-cell *matCellDef="let element"> {{element.redeemed_amount |
                                                currency:'USD'}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="transaction_id">
                                            <th mat-header-cell *matHeaderCellDef> Order ID </th>
                                            <td mat-cell *matCellDef="let element"> {{element.transaction_id}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="redeemed_at">
                                            <th mat-header-cell *matHeaderCellDef> Redeemed At </th>
                                            <td mat-cell *matCellDef="let element"> {{element.redeemed_at | date:
                                                'MM/dd/yyyy'}}
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="innerHistory">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: innerHistory;">
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" (click)="giftCardList(1)">
                    </tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                        [ngClass]="{ 'highlight-order-row': element?.id === selectedRow?.id }"
                        (click)="onRowClicked(element)" [class.example-expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
            </div>
            <mat-paginator [length]="filterCount" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]"
                (page)="yourHandler($event)">
            </mat-paginator>
        </div>
    </div>
</div>