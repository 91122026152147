<mat-tab-group>
  <mat-tab label="GIFT CARDS">
    <ng-template matTabContent>
      <app-gift-cards></app-gift-cards>
    </ng-template>
  </mat-tab>
  <mat-tab label="PHYSICAL GIFT CARDS">
    <ng-template matTabContent>
      <app-gift-card-physical></app-gift-card-physical>
    </ng-template>
  </mat-tab>
  <mat-tab label="REDEMPTIONS">
    <ng-template matTabContent>
      <app-offers-message-table></app-offers-message-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="GIFT CARD ORDERS">
    <ng-template matTabContent>
      <app-gift-card-order></app-gift-card-order>
    </ng-template>
  </mat-tab>
  <mat-tab label="CARDHOLDERS">
    <ng-template matTabContent>
      <app-gift-card-holders></app-gift-card-holders>
    </ng-template>
  </mat-tab>
</mat-tab-group>