<div *ngIf="isLoading; then processingBlock else resultBlock"></div>
<ng-template #processingBlock>
  <!-- <button mat-raised-button class="back" (click)="backClick()">
    Back
  </button> -->
  <button
    class="px-4 py-2 text-blue-500 font-semibold rounded border border-blue-500 hover:bg-blue-100 transition duration-200 ml-4 mt-[53px]"
    (click)="backClick()">
   Go Back
  </button>
  <p class="cardPayment cardPayment-mobile xs:!text-2xl">
    Card Payment In Progress:
  </p>
  <mat-spinner class="spinner"></mat-spinner>
</ng-template>
<ng-template #resultBlock>
  <!-- <div *ngIf="storeID == '92'">
    <div
      *ngIf="result.result_code === 'Approved' && result.response.response.transactionResult.responseCode === 'A'; then approvedBlock else notApprovedBlock">
    </div>
    <ng-template #approvedBlock>
      <div *ngIf="!store.feature_flag['manual_print_standard_checkout']">
        <p class="cardPayment cardPayment-mobile">
          Card Payment:
        </p>
        <p class="approving">Approved</p>
        <p class="codeStyle approved-message">{{result.message}}</p>
        <button mat-raised-button class="doneButtons" color="primary" (click)="doneClick()">
          Ok
        </button>
      </div>
      <div *ngIf="store.feature_flag['manual_print_standard_checkout']" class="flex flex-col gap-2 text-center mt-5">
        <p class="text-gray-600 text-3xl">Card Payment:</p>
        <p class="text-6xl text-green-600">Approved</p>
        <p class="text-gray-600 text-3xl">{{result.message}}</p>
        <app-manual-print-control [manualData]="manualData" (selectionEvent)="doneClick()"></app-manual-print-control>
      </div>
    </ng-template>
    <ng-template #notApprovedBlock>
      <p
        class="cardPayment-mobile colr mt-[78px] text-[33px] font-[Roboto] font-medium leading-4 tracking-[1.25px] text-center">
        Card Payment:
      </p>
      <p class="notApproving">Not Approved BHORB</p>
      <p class="codeStyle">{{result.message}}</p>
      <p *ngIf="result.response.response.transactionResult.responseCode === 'D' &&
          (result.response.response.transactionResult.responseMessage === 'Partial authorization is not supported.' ||
           result.response.response.transactionResult.responseMessage === 'Insufficient Funds')"
        class="codeStyle partialMessage">
        INSUFFICIENT FUNDS, PLEASE TRY A DIFFERENT PAYMENT TYPE. Customer will notice a partial authorization, but
        instruct that the partial authorization will auto void and not charge account.
      </p>
      <button type="button" class="statusButton absolute left-[22px] bottom-11"
        (click)="checkStatus(terminalId, orderHash)">
        Status
      </button>
      <button class="doneButtons absolute right-[22px] bottom-11" color="primary-color" (click)="backClick()">
        Back
      </button>
    </ng-template>
  </div> -->
  <!-- <div *ngIf="storeID != '92'"> -->
  <div>
    <div *ngIf="result.result_code === 'Approved'; then approvedBlock else notApprovedBlock">
    </div>
    <ng-template #approvedBlock>
      <div *ngIf="!store.feature_flag['manual_print_standard_checkout']">
        <p class="cardPayment cardPayment-mobile">
          Card Payment:
        </p>
        <p class="approving">Approved</p>
        <p class="codeStyle approved-message">{{result.message}}</p>
        <button mat-raised-button class="doneButtons" color="primary" (click)="doneClick()">
          Ok
        </button>
      </div>
      <!-- WITH PRINT OPTIONS -->
      <div *ngIf="store.feature_flag['manual_print_standard_checkout']" class="flex flex-col gap-2 text-center mt-5">
        <p class="text-gray-600 text-3xl">Card Payment:</p>
        <p class="text-6xl text-green-600">Approved</p>
        <p class="text-gray-600 text-3xl">{{result.message}}</p>
        <app-manual-print-control [manualData]="manualData" (selectionEvent)="doneClick()"></app-manual-print-control>
      </div>
    </ng-template>
    <ng-template #notApprovedBlock>
      <p
        class="cardPayment-mobile colr mt-[78px] text-[33px] font-[Roboto] font-medium leading-4 tracking-[1.25px] text-center">
        Card Payment:
      </p>
      <p class="notApproving">Not Approved</p>
      <p class="codeStyle">{{result.message}}</p>
      <button type="button" class="statusButton absolute left-[22px] bottom-11"
        (click)="checkStatus(terminalId, orderHash)">
        Status
      </button>
      <button class="doneButtons absolute right-[22px] bottom-11" color="primary-color" (click)="backClick()">
        Back
      </button>
    </ng-template>
  </div>
</ng-template>