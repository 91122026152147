<div
    class="p-8 bg-white rounded-lg shadow-md w-full max-w-[98%] sm:max-w-[95%] md:max-w-[850px] lg:max-w-[1100px] max-h-[90vh] overflow-y-auto">
    <!-- Modal Header -->
    <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-medium text-gray-800" *ngIf="forWhich == 'gift-card-purchase'">
            Activate Gift Card (${{ amount }})
        </h2>
        <h2 class="text-2xl font-medium text-gray-800" *ngIf="forWhich == 'balance-inquiry'">Balance Inquiry</h2>
        <h2 class="text-2xl font-medium text-gray-800" *ngIf="forWhich == 'gift-card-addvalue'">Add Value (${{ amount
            }})
        </h2>
        <h2 class="text-2xl font-medium text-gray-800" *ngIf="forWhich == 'gift-card-redeem'">Enter Gift Card Number
        </h2>
        <button type="button" (click)="onBack()" class="text-gray-500 hover:text-gray-800 text-lg">
            ✕
        </button>
    </div>
    <!-- Instruction Text -->
    <div>
        <p class="text-sm text-gray-500"
            *ngIf="forWhich == 'balance-inquiry' || forWhich == 'gift-card-addvalue' || forWhich == 'gift-card-redeem'">
            Key In or Scan
        </p>
    </div>
    <!-- Input and Keypad -->
    <div class="mt-6">
        <input #cardNumberInput type="text" [value]="cardNumber" disabled
            class="w-full px-4 py-3 text-lg border border-gray-300 rounded-md bg-gray-100 text-gray-700" />
        <div class="grid grid-cols-3 gap-4 mt-6">
            <button *ngFor="let key of keypadKeys" (click)="onKeyPress(key)"
                class="flex justify-center items-center w-full h-16 border border-gray-300 rounded-md bg-gray-100 hover:bg-gray-200 text-2xl font-medium">
                {{ key }}
            </button>
        </div>
    </div>
    <!-- Buttons -->
    <div class="flex justify-between mt-8 gap-4">
        <button type="button" (click)="onScan()"
            class="w-1/2 px-6 py-6 text-lg font-medium text-gray-700 bg-gray-200 border border-gray-300 rounded-lg hover:bg-gray-300">
            Scan
        </button>
        <button *ngIf="forWhich == 'gift-card-purchase'" type="button" (click)="onActivate()" [disabled]="!cardNumber"
            class="w-1/2 px-6 py-6 text-lg font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed">
            Activate
        </button>
        <button *ngIf="forWhich == 'balance-inquiry'" type="button" (click)="inquiry()" [disabled]="!cardNumber"
            class="w-1/2 px-6 py-6 text-lg font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed">
            Done
        </button>
        <button *ngIf="forWhich == 'gift-card-addvalue'" type="button" (click)="done()" [disabled]="!cardNumber"
            class="w-1/2 px-6 py-6 text-lg font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed">
            Done
        </button>
        <button *ngIf="forWhich == 'gift-card-redeem'" type="button" (click)="donePayment()" [disabled]="!cardNumber"
            class="w-1/2 px-6 py-6 text-lg font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed">
            Next
        </button>
    </div>
</div>