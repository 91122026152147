<app-progress-spinner *ngIf="isLoading"></app-progress-spinner>
<div class="grid grid-cols-3 grid-rows-2 grid-rows-2 gap-3">
  <div class="p-5 border border-gray-300 rounded-md bg-white shadow-lg h-[370px] row-span-2 shadow-none">
    <div class="grid grid-cols-2 gap-3">
      <div class="flex flex-col h-[165px]">
        <p class="text-xl">Number of Users</p>
        <p class="text-lg">{{userCount | number}}</p>
        <p class="text-sm text-gray-500">total users</p>
      </div>
      <div class="graph">
        <app-total-user-chart></app-total-user-chart>
      </div>
    </div>

    <div class="items h-40 overflow-x-hidden overflow-y-auto">
      <div *ngFor="let bar of countData; let i = index" class="mb-2">
        <div class="flex justify-between">
          <p>{{bar.label}}</p>
          <p>{{bar.percent}}%</p>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-full">
          <div class="h-full bg-green-500 rounded-full" [ngStyle]="{'width': bar.percent + '%'}"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="p-5 border border-gray-300 rounded-md bg-white shadow-lg h-[179px] vxs:h-[120px] xs:h-[120px] shadow-none">
    <div class="grid grid-cols-1 gap-3">
      <div class="flex flex-col h-[165px]">
        <p class="text-xl">Total Points Earned</p>
        <p class="text-lg">{{pointsEarned | number}}</p>
        <p class="text-sm text-gray-500">in total</p>
      </div>
    </div>
  </div>

  <div
    class="p-5 border border-gray-300 rounded-md bg-white shadow-lg h-[179px] vxs:h-[120px] xs:h-[120px] shadow-none">
    <div class="grid grid-cols-1 gap-3">
      <div class="flex flex-col h-[165px]">
        <p class="text-xl">Total Points Expired</p>
        <p class="text-lg">{{expiredPoints | number}}</p>
        <p class="text-sm text-gray-500">in total</p>
      </div>
    </div>
  </div>

  <div
    class="p-5 border border-gray-300 rounded-md bg-white shadow-lg h-[179px] vxs:h-[120px] xs:h-[120px] shadow-none">
    <div class="grid grid-cols-1 gap-3">
      <div class="flex flex-col h-[165px]">
        <p class="text-xl">Total Points Redeemed</p>
        <p class="text-lg">{{redeemedPoints | number}}</p>
        <p class="text-sm text-gray-500">in total</p>
      </div>
    </div>
  </div>

  <div
    class="p-5 border border-gray-300 rounded-md bg-white shadow-lg h-[179px] vxs:h-[120px] xs:h-[120px] shadow-none">
    <div class="grid grid-cols-1 gap-3">
      <div class="flex flex-col h-[165px]">
        <p class="text-xl">Total Points Outstanding</p>
        <p class="text-lg">{{outstansingPoints | number}}</p>
        <p class="text-sm text-gray-500">in total</p>
      </div>
    </div>
  </div>
</div>
<br>
<div *ngIf="isDownLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p style="text-align: center;">Please wait, exporting data...</p>
</div>
<div *ngIf="isDownloadComplete" style="text-align: center; color: green; margin-top: 10px;">
  Downloading Done!
</div>
<div class="flex flex-col">
  <form [formGroup]="Daterange" autocomplete="off">
    <div class="flex flex-row xs:flex-wrap md:flex-nowrap items-center gap-3 my-5">
      <div style="margin-top: 6px;" class="searches">
        <input class="srch-field border border-gray-300" id="search" name="search" placeholder="Search" #serch
          formControlName="search_str">
      </div>
      <div class="flex flex-row xs:w-full xs:gap-[7.5px] gap-[13px]">
        <mat-form-field appearance="fill" class="start" subscriptSizing="dynamic">
          <mat-label>Start Date</mat-label>
          <input matInput [min]="maxDateCheck" [matDatepicker]="dp3" placeholder="Choose a date" readonly="true"
            formControlName="from_date">
          <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
          <mat-datepicker #dp3></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" class="end" subscriptSizing="dynamic">
          <mat-label>End Date</mat-label>
          <input matInput [min]="Daterange.get('from_date').value" [matDatepicker]="dp1" placeholder="Choose a date"
            readonly="true" formControlName="to_date">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker [disabled]="!Daterange.get('from_date').value" #dp1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="flex flex-row w-full xs:gap-[7.5px] gap-[13px]">
        <button mat-raised-button color="primary" class="xs:w-full sm:w-full apply-btn-colr"
          (click)="filterSubmit()">Search</button>
        <button mat-raised-button color="primary" class="xs:w-full sm:w-full apply-btn-colr md:w-[11%]"
          (click)="clearFilter()">Clear</button>
        <button mat-raised-button color="primary" *ngIf="store.feature_flag['EXPORT_LOYALTY_POINT_DATA']" class="xs:w-full sm:w-full apply-btn-colr md:w-[11%]"
        (click)="exportData()">Export</button>
      </div>
    </div>
  </form>
  <div style="margin-top: 20px;" class="loyalty-table-div">
    <div class="loyalty-table-mobile-scroll">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortDirection="desc">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.name}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="phone_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
          <td mat-cell *matCellDef="let element"> {{element.phone_number | phone}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status >= 5">Platinum</span>
            <span *ngIf="element.status == 4">Gold</span>
            <span *ngIf="element.status >= 2 && element.status <= 3">Silver</span>
            <span *ngIf="element.status == 1">Bronze</span>
            <span *ngIf="element.status == null || element.status == 0">No Status</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="user_order_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Visits </th>
          <td mat-cell *matCellDef="let element"> {{element.user_order_visit}} </td>
        </ng-container>
        <ng-container matColumnDef="total_spend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Spend </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.total_spend != null">{{element.total_spend | currency: 'USD'}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="current_points">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Current Points </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.current_points != null">{{element.current_points}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="expired_points">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Expired Points </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.expired_points != null">{{element.expired_points}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="points_redeemed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Points Redeemed </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.points_redeemed != null">{{element.points_redeemed}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="average_spend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Average Spend </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.average_spend != ''">{{element.average_spend | currency: 'USD'}}</span>
            <span *ngIf="element.average_spend == null || element.average_spend == ''">0.00</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="last_visit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Visit </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="longDate(element) == -1"></span>
            <span *ngIf="longDate(element) == 0">Today</span>
            <span *ngIf="longDate(element) == 1">Yesterday</span>
            <span *ngIf="longDate(element) > 1">{{longDate(element) | number: '1.0-0'}} days ago</span>
          </td>

          <td mat-cell *matCellDef="let element" class="last-border-set">
            <div fxLayoutAlign="center center">
              <span class=" date-color-set date-too">
                <span class="set how-to-long-date">
                  {{element.StartDate}} - {{element.DueDate}}
                </span>
                <span class="not-set days-data" matTooltip="{{longDate(element)}} Days"
                  [matTooltipClass]="position.value">
                  {{longDate(element)}}d</span>
              </span>
            </div>
          </td>

        </ng-container>
        <ng-container matColumnDef="since">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Member Since </th>
          <td mat-cell *matCellDef="let element"> {{element.since | date}} </td>
        </ng-container>
        <ng-container matColumnDef="enrolled_status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolled Status </th>
          <td mat-cell *matCellDef="let element"> {{element.enrolled_status}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"
              (click)="onRowClicked(element)">
              <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expand-table-points">
                <div class="exapnd-row expand-td">
                  <div class="points-redeemed">
                    <p>Points Redeemed: <span class="value md:!ml-0">{{userRedeemedPoints}}</span></p>
                  </div>
                  <div class="current-points w-[26%]">
                    <p>Current Point Balance: <span class="value">{{currentPoints}}</span></p>
                  </div>
                  <div class="input-points">
                    <input class="field border-[1px] border-black" type="number" min="0" id="quantity"
                      [(ngModel)]="input" name="quantity" #quantity>
                  </div>
                  <div class="btn-add-sub md:-mt-1">
                    <button mat-raised-button color="primary" class="add-btn apply-btn-colr md:!-mt-1"
                      (click)="addPoints(quantity.value)"
                      [disabled]="!quantity.value || quantity.value == 0">Add</button>
                    <button mat-raised-button color="accent" class="subtract-btn md:!-mt-1"
                      (click)="subtractPoints(quantity.value)"
                      [disabled]="!quantity.value || quantity.value == 0 || currentPoints < quantity.value">Subtract</button>
                  </div>
                </div>
                <div>
                  <form [formGroup]="innerDaterange" autocomplete="off">
                    <div class="inner-filter-button">
                      <div fxLayout.xs="column" fxLayout.sm="row wrap" fxLayout.md="row wrap" fxLayout.lg="row"
                        class="inner-filter md:!w-[70%] md:!flex-nowrap xs:!flex-nowrap xs:!flex-row flex">
                        <div style="margin-left: 10px;" fxFlex.xs="100" class="filter-field-height">
                          <mat-form-field appearance="fill" class="start1">
                            <mat-label>Start Date</mat-label>
                            <input matInput [min]="maxDateCheck" [matDatepicker]="dp3" placeholder="Choose a date"
                              readonly="true" formControlName="from_date_inner">
                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                            <mat-datepicker #dp3></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div style="margin-left: 12px;" class="filter-field-height">
                          <mat-form-field appearance="fill" class="end1">
                            <mat-label>End Date</mat-label>
                            <input matInput [min]="innerDaterange.get('from_date_inner').value"
                              [disabled]="!innerDaterange.get('from_date_inner').value" [matDatepicker]="dp1"
                              placeholder="Choose a date" readonly="true" formControlName="to_date_inner">
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker #dp1></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="content-btn">
                        <button mat-raised-button color="primary" class="search-btn apply-btn-colr"
                          (click)="innerFilterSubmit()">Search</button>
                        <button mat-raised-button color="primary" class="search-btn apply-btn-colr"
                          (click)="innerClearFilter()">Clear</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div style="width: 100%;max-height: 200px;overflow-y: auto;">
                  <table mat-table [dataSource]="innerDatasource" style="background-color: #ededed;">
                    <ng-container matColumnDef="order_id">
                      <th mat-header-cell *matHeaderCellDef> Order Id </th>
                      <td mat-cell *matCellDef="let element"> {{element.order_counter}} </td>
                    </ng-container>
                    <ng-container matColumnDef="order_date">
                      console.log(row);
                      <th mat-header-cell *matHeaderCellDef> Order Date </th>
                      <td mat-cell *matCellDef="let element"> {{element.submitted_at | date: 'MM/dd/yyyy'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="order_time">
                      <th mat-header-cell *matHeaderCellDef> Order Time </th>
                      <td mat-cell *matCellDef="let element"> {{element.submitted_at | date: 'hh:mm a'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="payment_type">
                      <th mat-header-cell *matHeaderCellDef> Payment Type </th>
                      <td mat-cell *matCellDef="let element"> {{element.payment_type}} </td>
                    </ng-container>
                    <ng-container matColumnDef="total_spend">
                      <th mat-header-cell *matHeaderCellDef> Total Spend </th>
                      <td mat-cell *matCellDef="let element"> {{element.total | currency: 'USD'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="occasion">
                      <th mat-header-cell *matHeaderCellDef> Order Occasion </th>
                      <td mat-cell *matCellDef="let element"> {{element.occasion}} </td>
                    </ng-container>
                    <ng-container matColumnDef="pos_online">
                      <th mat-header-cell *matHeaderCellDef> Order Method </th>
                      <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.is_pos==false"> ONLINE </span>
                        <span *ngIf="element.is_pos==true"> POS </span>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="innerLoyaltyDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: innerLoyaltyDisplayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand" (click)="sortingHeader()"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
          [ngClass]="{ 'highlight-order-row': element?.id === selectedRow?.id }" (click)="onRowClicked(element)"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <mat-paginator [length]="filterCount" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" (page)="yourHandler($event)">
    </mat-paginator>
  </div>
</div>